.bulk_list_parent {
    display: flex;
    flex-direction: column;
    width: 256px;
    margin-top: 10px;
    height: calc(100vh - 410px);
    overflow-y: scroll;
    overflow-x: hidden;
}

.bulk_list_parent::-webkit-scrollbar {
    display: none;
}

.ship_filter_area_countries::-webkit-scrollbar {
    display: none;
}

.ship_filter_area_cities::-webkit-scrollbar {
    display: none;
}

.shipment_main_statuses .ballet_group_f:not(:last-child) {
    margin-right: 29px;
}

.bulk_list_parent_expanded {
    display: flex;
    flex-direction: column;
    width: -webkit-fill-available;
    height: calc(100vh - 410px);
    overflow-y: scroll;
    overflow-x: hidden;
}

.bulk_expanded_list {
    display: flex;
    flex-direction: row;
    width: 1111px;
    justify-content: space-between;
    margin-top: 10px;
}

.ballet_group_f {
    min-width: 256px;
    width: 256px;
}

.bulk_expanded_list_column {
    width: -webkit-fill-available;
}

.bulk_expanded_list .bulk_expanded_list_column:not(:last-of-type) {
    margin-right: 23px;
}

.bulk_element_with_status .bulk_element {
    display: flex;
    flex-direction: column;
    width: 256px;
    border: 0.5px solid #DDDDDD;
    border-radius: 10px;
    box-shadow: 0px 3px 3px rgb(0, 0, 0, 0.1);
    min-height: 57px;
    width: 256px;
    background-color: #F7F7F7;
}

.bulk_element_right_icon {
    min-width: 29px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.bulk_element_with_status .bulk_element_usdt {
    display: flex;
    flex-direction: column;
    width: 256px;
    border: 0.5px solid #DDDDDD;
    border-radius: 10px;
    box-shadow: 0px 3px 3px rgb(0, 0, 0, 0.1);
    min-height: 57px;
    width: 256px;
    background-color: #F7F7F7;
    justify-content: space-evenly;
}

.bulk_element_with_status_selected .bulk_element_usdt {
    display: flex;
    flex-direction: column;
    width: 256px;
    border: 0.5px solid #DDDDDD;
    border-radius: 10px;
    min-height: 57px;
    width: 256px;
    position: relative;
    z-index: 4;
    background-color: #F7F7F7;
    justify-content: space-evenly;
}

.bulk_element_with_status_selected .bulk_element {
    display: flex;
    flex-direction: column;
    width: 256px;
    border: 0.5px solid #DDDDDD;
    border-radius: 10px;
    height: 57px;
    width: 256px;
    position: relative;
    z-index: 4;
    background-color: #F7F7F7;
}

.bulk_element {
    cursor: pointer;
}

.bulk_element_usdt {
    cursor: pointer;
}

.bulk_element_name {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    cursor: pointer;
    height: 32px;
}

.bulk_element_name .bulk_element_name_icon {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: 10px;
}

.bulk_element_name .bulk_element_name_icon .bulk_name {
    display: flex;
    margin-top: 8px;
    font-family: "SF-Pro-Rounded-regular";
    font-size: 12px;
    letter-spacing: calc((10 / 1000) * 1em);
    color: #5D5D5D;
    align-items: center;
}

.bulk_element_name .bulk_element_name_icon .bulk_name_tether {
    display: flex;
    font-family: "SF-Pro-Rounded-regular";
    font-size: 16px;
    letter-spacing: calc((10 / 1000) * 1em);
    color: #404040;
    align-items: end;
}

.bulk_element_name .bulk_element_name_icon .bulk_name_odoo {
    display: flex;
    font-family: "SF-Pro-Rounded-regular";
    font-size: 12px;
    letter-spacing: calc((10 / 1000) * 1em);
    color: #5D5D5D;
    align-items: end;
}

.usdt_class {
    display: flex;
    margin-left: 10px;
    font-family: "SF-Pro-Rounded-light";
    font-size: 12px;
    letter-spacing: calc((10 / 1000) * 1em);
    color: #404040;
    align-items: flex-end;
}

.bulk_element_name .bulk_element_name_icon .bulk_icon {
    display: flex;
    width: 20px;
    height: 21px;
    justify-content: flex-start;
    align-items: flex-start;
    margin-right: 10px;
}

.bulk_element_name .bulk_element_right_icon {
    margin-right: 15px;
    height: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.bulk_element_name .bulk_element_right_icon svg {
    margin-right: 5px;
}

.bulk_element_data {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 25px;
    align-items: center;
}

.bulk_element_data .bulk_element_country_data {
    display: flex;
    margin-left: 12px;
    align-items: center;
}

.bulk_element_data .bulk_element_detail_data {
    display: flex;
    margin-right: 10px;
    align-items: center;
}

.bulk_element_data .bulk_element_detail_data svg {
    display: flex;
}

.bulk_element_data .bulk_element_detail_data .products_order_count {
    display: flex;
    font-family: "SF-Pro-Rounded-regular";
    align-items: center;
    font-size: 10px;
    color: #5d5d5d;
    letter-spacing: calc((10 / 1000) * 1em);
}

.bulk_element_data .bulk_element_detail_data .bulk_element_weight {
    display: flex;
    font-family: "SF-Pro-Rounded-regular";
    align-items: center;
    font-size: 10px;
    color: #5d5d5d;
}

.bulk_element_time {
    display: flex;
    flex-direction: row;
}

.bulk_element_time .bulk_element_time_num {
    display: flex;
    font-family: "SF-Pro-Rounded-regular";
    align-items: center;
    font-size: 10px;
    color: #8E8E8E;
}

.bulk_element_time .bulk_element_time_unit {
    display: flex;
    font-family: "SF-Pro-Rounded-light";
    align-items: center;
    font-size: 10px;
    color: #8E8E8E;
    margin-right: 5px;
}

.bulk_element_time .bulk_element_time_unit_odoo {
    display: flex;
    font-family: "SF-Pro-Rounded-regular";
    align-items: center;
    font-size: 12px;
    color: #404040;
}

.odoo_ticket_time_right {
    display: flex;
    font-family: "SF-Pro-Rounded-light";
    align-items: center;
    font-size: 10px;
    color: #8E8E8E;
    margin-right: 10px;
    height: 32px;
}

.odoo_ticket_time_right svg {
    margin-right: 5px;
}

.bulk_element_data .bulk_element_detail_data svg:not(:first-child) {
    margin-left: 10px;
}

.bulk_element_data .bulk_element_detail_data svg:last-child {
    margin-right: 35px;
}

.bulk_element_data .bulk_element_country_data .bulk_element_country_icon {
    display: flex;
    margin-right: 5px;
    width: 10px;
    height: 10px;
}

.bulk_element_country_icon_odoo {
    display: flex;
    margin-right: 17px;
    width: 10px;
    height: 15px;
    font-family: "Noto Emoji" !important;
    font-size: 9px;
}

.bulk_element_data .bulk_element_country_data .bulk_element_country_name {
    display: flex;
    font-family: "SF-Pro-Rounded-regular";
    align-items: center;
    font-size: 10px;
    color: #5D5D5D;
    letter-spacing: calc((10 / 1000) * 1em);
    overflow: hidden;
    text-wrap: nowrap;
    max-width: 65px;
}

.bulk_element_data .bulk_element_country_data .bulk_element_country_name_odoo {
    display: flex;
    font-family: "SF-Pro-Rounded-regular";
    align-items: center;
    font-size: 10px;
    color: #5D5D5D;
    letter-spacing: calc((10 / 1000) * 1em);
    overflow: hidden;
    text-wrap: nowrap;
    width: fit-content;
}

.bulk_element_with_status_selected {
    margin-bottom: 7px;
}

.status_area_element {
    min-height: 42px;
    border-radius: 0px 0px 20px 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    position: relative;
    width: 256px;
    margin-top: -17px;
    z-index: 3;
    cursor: pointer;
    animation-name: slideIn100;
    animation-duration: 0.25s;
    animation-direction: normal;
}

.status_area_element_full_options {
    min-height: 42px;
    border-radius: 0px 0px 20px 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    position: relative;
    width: 256px;
    margin-top: -17px;
    z-index: 3;
    cursor: pointer;
    animation-name: slideIn80;
    animation-duration: 0.25s;
    animation-direction: normal;
}

.status_area_all_in {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 42px;
    display: flex;
    margin-top: 0px;
    z-index: 3;
    cursor: pointer;
    width: 256px;
    position: relative;
    border-radius: 0px 0px 20px 20px;
    animation-name: slideIn60;
    animation-duration: 0.25s;
    animation-direction: normal;
    box-shadow: 0px 3px 3px rgb(0, 0, 0, 0.1);
}

.status_area_element_with_order {
    border-radius: 0px 0px 20px 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    position: relative;
    width: 256px;
    margin-top: -17px;
    z-index: 2;
    /* height: 30px; */
    align-items: flex-end;
    animation-name: slideIn100;
    animation-duration: 0.25s;
    animation-direction: normal;
    box-shadow: 0px 3px 3px rgb(0, 0, 0, 0.1);
}

.status_area_all_in_with_order {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 42px;
    display: flex;
    top: 0px;
    z-index: 2;
    cursor: pointer;
    width: 256px;
    position: relative;
    border-radius: 0px 0px 10px 10px;
    background-color: #F7F7F7;
    animation-name: slideIn100;
    animation-duration: 0.25s;
    animation-direction: normal;
}

.bulk_orders_area {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 42px;
    display: flex;
    z-index: 4;
    width: 256px;
    position: relative;
    border-radius: 0px 0px 20px 20px;
    background-color: unset;
    user-select: text;
}

.bulk_orders_area_comment {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 42px;
    display: flex;
    margin-top: -17px;
    z-index: 3;
    width: 256px;
    position: relative;
    border-radius: 0px 0px 20px 20px;
    background-color: #F7F7F7;
    animation-name: slideIn100;
    animation-duration: 0.25s;
    animation-direction: normal;
    user-select: text;
}

.bulk_orders_area_comment_inner {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    user-select: text;
    margin-top: 29px;
    color: #FFFCBC;
    margin-left: 15px;
    margin-right: 15px;
    margin-bottom: 15px;
    word-break: break-all;
    width: 240px;
    max-height: 100px;
    overflow-y: scroll;
    font-family: "SF-Pro-Rounded-light";
    overflow-x: hidden;
}

@keyframes slideIn60 {
    0% {
        transform: translateY(-60%);
    }

    100% {
        transform: translateY(0%);
    }
}

@keyframes slideIn70 {
    0% {
        transform: translateY(-70%);
    }

    100% {
        transform: translateY(0%);
    }
}

@keyframes slideIn80 {
    0% {
        transform: translateY(-80%);
    }

    100% {
        transform: translateY(0%);
    }
}

@keyframes slideIn90 {
    0% {
        transform: translateY(-90%);
    }

    100% {
        transform: translateY(0%);
    }
}

@keyframes slideIn100 {
    0% {
        transform: translateY(-100%);
    }

    100% {
        transform: translateY(0%);
    }
}

.status_area_all_in .status_area_all_element:first-child {
    margin-top: 30px;
}

.status_area_all_in_with_order .status_area_all_element:first-child {
    margin-top: 30px;
}

.status_area_all_element_row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.status_area_all_element {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: -webkit-fill-available;
}

.bulk_element_with_status {
    margin-bottom: 14px;
    position: relative;
}

.status_area_element_name {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.status_area_element_name_icon {
    margin-right: 5px;
    min-width: 10px;
}

.status_area_element_time {
    display: flex;
    flex-direction: row;
    margin-right: 10px;
    align-items: center;
}

.status_area_element_name_name {
    font-family: "SF-Pro-Rounded-regular";
    font-size: 10px;
    color: #9A9A9A;
    display: flex;
    flex-direction: row;
}

.status_area_element_name_name svg {
    margin-right: 5px;
}

.status_area_element_time_time {
    color: #5D5D5D;
    font-family: "SF-Pro-Rounded-regular";
    font-size: 10px;
    margin-right: 5px;
}


.status_area_element_name_row {
    display: flex;
    flex-direction: row;
    margin-bottom: 9px;
    margin-left: 13px;
    align-items: center;
}

.status_area_element_name_icon_row {
    margin-right: 5px;
    min-width: 10px;
}

.status_area_element_time_row {
    display: flex;
    flex-direction: row;
    margin-right: 10px;
    margin-bottom: 9px;
    align-items: center;
}

.status_area_element_name_name_row {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.status_area_element_name_name_row_statusname {
    font-family: "SF-Pro-Rounded-regular";
    font-size: 10px;
    color: #C1C1C1;
}

.status_area_element_name_name_row_current2 {
    font-family: "SF-Pro-Rounded-regular";
    font-size: 10px;
    color: #969696;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: 15px;
}

.status_area_element_name_name_row_current {
    font-family: "SF-Pro-Rounded-regular";
    font-size: 10px;
    color: #C1C1C1;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.status_area_element_name_name_row_username {
    font-family: "SF-Pro-Rounded-regular";
    font-size: 10px;
    color: #C1C1C1;
}

.status_area_element_time_time_row {
    color: #C1C1C1;
    font-family: "SF-Pro-Rounded-regular";
    font-size: 10px;
    margin-right: 5px;
}

.status_area_all_in_with_order .status_area_all_element:last-child .status_area_all_element_row .status_area_element_name_row .status_area_element_name_name_row .status_area_element_name_name_row_statusname {
    color: #5D5D5D;
}

.status_area_all_in_with_order .status_area_all_element:last-child .status_area_all_element_row .status_area_element_name_row .status_area_element_name_name_row .status_area_element_name_name_row_username {
    color: #969696;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.status_area_all_in_with_order .status_area_all_element:last-child .status_area_all_element_row .status_area_element_name_row .status_area_element_name_name_row .status_area_element_name_name_row_username img {
    margin-right: 5px;
}

.status_area_all_in_with_order .status_area_all_element:last-child .status_area_all_element_row .status_area_element_name_row .status_area_element_name_name_row_current {
    color: #969696;
}

.status_area_all_in_with_order .status_area_all_element:last-child .status_area_all_element_row .status_area_element_time_row .status_area_element_time_time_row {
    color: #8E8E8E;
}

.status_area_all_in_with_order .status_area_all_element:last-child .status_area_all_element_row:last-child .status_area_element_time_row .status_area_element_time_time_row {
    color: #5D5D5D;
}

.status_area_all_in .status_area_all_element:last-child .status_area_all_element_row .status_area_element_name_row .status_area_element_name_name_row .status_area_element_name_name_row_statusname {
    color: #5D5D5D;
}

.status_area_all_in .status_area_all_element:last-child .status_area_all_element_row .status_area_element_name_row .status_area_element_name_name_row .status_area_element_name_name_row_username {
    color: #969696;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.status_area_all_in .status_area_all_element:last-child .status_area_all_element_row .status_area_element_name_row .status_area_element_name_name_row .status_area_element_name_name_row_username img {
    margin-right: 5px;
}

.status_area_all_in .status_area_all_element:last-child .status_area_all_element_row .status_area_element_name_row .status_area_element_name_name_row_current {
    color: #969696;
}

.status_area_all_in .status_area_all_element:last-child .status_area_all_element_row .status_area_element_time_row .status_area_element_time_time_row {
    color: #8E8E8E;
}

.status_area_all_in .status_area_all_element:last-child .status_area_all_element_row:last-child .status_area_element_time_row .status_area_element_time_time_row {
    color: #5D5D5D;
}

.bulk_orders_area_element:last-child {
    margin-bottom: 5px;
}

.bulk_orders_area_element_icon {
    margin-right: 10px;
    width: 15px;
    height: 16px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.bulk_orders_area_element_text {
    font-size: 14px;
    color: #5D5D5D;
    font-family: "SF-Pro-Rounded-regular";
    word-break: break-word;
    margin-right: 5px;
    display: flex;
    flex-direction: row;
    align-items: center;
    user-select: text;
    position: relative;
}

.bulk_orders_area_element {
    /* background-color: #F7F8F3; */
    border-radius: 5px;
    min-height: 16px;
    margin-top: 10px;
    margin-left: 15px;
    margin-right: 3px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: -webkit-fill-available;
    word-break: break-all;
}

.bulk_order_icon_name {
    display: flex;
    min-width: 80px;
    margin-left: 4px;
    flex-direction: row;
    align-items: center;
}

.bulk_order_icon {
    margin-right: 10px;
}

.bulk_order_name {
    font-family: "SF-Pro-Rounded-regular";
    font-size: 12px;
    letter-spacing: calc((10 / 1000) * 1em);
    text-transform: capitalize;
    color: #5D5D5D;
}

.bulk_order_details {
    display: flex;
    min-width: 118px;
    margin-right: 10px;
    justify-content: space-between;
    align-items: center;
    color: #5D5D5D;
    font-family: "SF-Pro-Rounded-regular";
    font-size: 10px;
}

.bulk_order_details div {
    min-width: 12px;
    color: #5D5D5D;
    font-family: "SF-Pro-Rounded-regular";
    font-size: 10px;
}

.bulk_order_time {
    text-transform: none;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.status_area_element_with_order_chat {
    height: 65px;
    border-radius: 0px 0px 20px 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    position: relative;
    width: 256px;
    margin-top: -17px;
    z-index: 1;
    /* height: 30px; */
    align-items: flex-end;
    animation-name: slideIn100;
    animation-duration: 0.25s;
    animation-direction: normal;
    box-shadow: 0px 3px 3px rgb(0, 0, 0, 0.1);
}

.status_area_element_name_name_row_current svg {
    margin-right: 5px;
}

.status_area_element_with_order_chat_img {
    width: 20px;
    height: 20px;
    border-radius: 10px;
    cursor: initial;
}

.status_area_element_with_order_chat_container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 10px;
    margin-left: 15px;
    margin-right: 15px;
    height: 30px;
}

.status_area_element_with_order_chat_container_inner {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}

.status_area_element_with_order_chat_container_inner img {
    margin-right: 10px;
}

.status_area_element_name_name_row_current svg {
    margin-right: 5px;
}

.status_area_element_child {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 256px;
    cursor: pointer;
    margin-top: 25px;
    margin-bottom: 10px;
}

.admin_edit_rate_ticket {
    margin-left: 15px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    position: relative;
}

.admin_edit_rate_ticket_input {
    border-bottom: 1px solid rgb(255, 255, 255);
    width: 80px;
    caret-color: rgb(255, 255, 255);
    font-size: 12px;
    color: #404040;
    font-family: "SF-Pro-Rounded-regular";
}

.admin_edit_rate_ticket_input:focus {
    border-bottom: 1px solid rgb(255, 255, 255);
    width: 80px;
    caret-color: rgb(255, 255, 255);
    font-size: 12px;
    color: #404040;
    font-family: "SF-Pro-Rounded-regular";
}

.admin_edit_rate_ticket_input:disabled {
    border-bottom: none;
    width: -webkit-fit-content;
    overflow: hidden;
    max-width: 50px;
    width: -moz-fit-content;
    width: fit-content;
    caret-color: rgb(255, 255, 255);
    font-size: 12px;
    color: #FFFFFF !important;
    font-family: "SF-Pro-Rounded-regular";
    user-select: text;
}

.admin_send_ticket_button {
    font-size: 12px;
    color: #404040;
    font-family: "SF-Pro-Rounded-regular";
    margin-right: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    height: 30px;
    width: 52px;
}

@keyframes activeButton {
    0% {
        width: 52px;
    }

    100% {
        width: 220px;
    }
}

.admin_send_ticket_button_active {
    font-size: 12px;
    color: #404040;
    font-family: "SF-Pro-Rounded-regular";
    margin-right: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    width: 170px;
    max-width: 170px;
    min-width: 52px;
    border-radius: 20px;
    background-color: #3CFEAD;
    height: 30px;
    margin-left: 10px;
    animation-name: activeButton;
    animation-duration: 0.25s;
    animation-fill-mode: forwards;
    animation-iteration-count: 1;
    overflow: hidden;
    text-wrap: nowrap;
    box-shadow: 0px 3px 3px rgb(0, 0, 0, 0.1);
}

.admin_send_ticket_button_active svg {
    margin-left: 15px;
    margin-right: 40px;
}

.admin_send_ticket_button svg {
    margin-left: 10px;
}

.admin_chat_with_admin_tickets_button {
    width: 170px;
    height: 30px;
    border-radius: 20px;
    background-color: #FAFAFA;
    font-size: 12px;
    color: #969696;
    font-family: "SF-Pro-Rounded-light";
    display: flex;
    align-items: center;
    justify-content: flex-start;
    cursor: pointer;
}

.admin_chat_with_admin_tickets_button svg {
    margin-left: 15px;
    margin-right: 10px;
}

.admin_chat_with_admin_tickets_button_admin_name {
    font-size: 12px;
    color: #7C7C7C;
    font-family: "SF-Pro-Rounded-regular";
}

@keyframes collapseItemadd {
    0% {
        left: -500px;
        margin-top: -100px;
    }

    70% {
        margin-top: 0px;
        left: -500px;
        opacity: 0;
    }

    100% {
        left: 0px;
        margin-top: 0px;
        opacity: 1;
    }
}

.removedItem {
    position: relative;
    animation-duration: 1s;
    animation-timing-function: linear;
    animation-fill-mode: forwards;
}

.addedItem {
    position: relative;
    animation-name: collapseItemadd;
    animation-duration: 1s;
    animation-timing-function: linear;
    animation-fill-mode: forwards;
}

@keyframes greenItemadd {
    0% {
        box-shadow: 0px 3px 6px rgb(26, 255, 26);
    }

    80% {
        box-shadow: 0px 3px 6px rgb(26, 255, 26);
    }

    100% {
        box-shadow: 0px 3px 6px rgb(255, 255, 255);
    }
}

.addedItemGrean .bulk_element_with_status .bulk_element_usdt {
    animation-name: greenItemadd;
    animation-duration: 10s;
    animation-timing-function: linear;
    animation-fill-mode: forwards;
}

.context_menu_ticket {
    position: fixed;
    z-index: 99999999999;
    border-radius: 10px;
    box-shadow: 0px 3px 6px rgb(155, 155, 155);
    padding: 2px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    background-color: #F7F7F7;
}

.context_menu_ticket_item {
    background-color: #F7F7F7;
    cursor: pointer;
    width: 100%;
    text-align: center;
    margin-bottom: 5px;
    border-radius: 5px;
    padding: 5px;
    font-size: 14px;
    color: #555555;
    font-family: "SF-Pro-Rounded-regular";
}

.context_menu_ticket_item:hover {
    background-color: #C1C1C1;
}