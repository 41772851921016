.dark-theme .focus-visible {
    outline: 2px solid rgba(70, 108, 119, 0.3);
}

.dark-theme .header {
    background: rgb(42, 47, 50)
}

.dark-theme .search-wrapper {
    background-color: none;
}

.dark-theme .search__back-btn {
    color: inherit;
}

.dark-theme .search {
    background: #323739;
    color: #FAFAFA;
}

.dark-theme .forward_image_preview {
    background: #0D151A;
}