.dark-theme .loader {
    background: rgb(18.6, 28.22, 33.4);
}

.dark-theme .loader__logo-wrapper::after {
    background: linear-gradient(90deg, rgba(18.6, 28.22, 33.4, .5) 0, rgba(18.6, 28.22, 33.4, .5) 33.33%, rgba(18.6, 28.22, 33.4, 0) 44.1%, rgba(18.6, 28.22, 33.4, 0) 55.8%, rgba(18.6, 28.22, 33.4, .5) 66.66%, rgba(18.6, 28.22, 33.4, .5));
}

.dark-theme .loader__logo {
    fill: rgb(108, 113, 117);
}

.dark-theme .loader__progress {
    background: rgb(42, 47, 50);
}

.dark-theme .loader__progress::after {
    background: rgb(12, 137, 124);
}

.dark-theme .loader__title {
    color: rgba(241, 241, 242, 0.88);
}

.dark-theme .loader__desc {
    color: rgba(241, 241, 242, 0.45)
}