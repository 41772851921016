.ship_package_filter {
    display: flex;
    height: 20px;
    background-color: #F7F8F3;
    width: calc((100% - 87px)/4);
    margin-top: 14px;
    margin-bottom: 10px;
    justify-content: space-between;
    background-position: center;
    background-image: url("data:image/svg+xml,%3csvg width='99%25' height='99%25' style='padding-left: 1px;' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='calc(100%25 - 2px)' height='99%25' fill='none' rx='5' ry='5' stroke='%238E8E8EFF' stroke-width='1' stroke-dasharray='2%2c3' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
    border-radius: 5px;
    min-width: 256px;
}

.ship_package_filter_child:first-child {
    margin-left: 10px;
}

.shipment_main_statuses .ship_package_filter:not(:last-child) {
    margin-right: 29px;
}

.ship_package_filter_child:last-child {
    margin-right: 10px;
}

.ship_package_filter_child {
    display: flex;
    margin-top: 4px;
    margin-bottom: 5px;
    cursor: pointer;
}

.ship_package_filter_child_selected {
    display: flex;
    margin-top: 4px;
    margin-bottom: 5px;
    cursor: pointer;
}

.ship_package_filter_child .ship_package_filter_child_icon {
    display: flex;
    margin-top: 1px;
    margin-right: 5px;
}

.ship_package_filter_child .ship_package_filter_child_count {
    margin-top: 1px;
    font-size: 8px;
    color: #5D5D5D;
    font-family: "SF-Pro-Rounded-light";
    display: flex;
    flex-direction: row;
    align-items: center;
}

.ship_package_filter_child .ship_package_filter_child_count_selected {
    margin-top: 1px;
    font-size: 8px;
    color: #5D5D5D;
    font-family: "semi-bold";
    display: flex;
    flex-direction: row;
    align-items: center;
}

.ship_package_filter_expand {
    display: flex;
    height: 20px;
    background-color: #F7F8F3;
    background-position: center;
    background-image: url("data:image/svg+xml,%3csvg width='99%25' height='99%25' style='padding-left: 1px;' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='calc(100%25 - 2px)' height='99%25' fill='none' rx='5' ry='5' stroke='%238E8E8EFF' stroke-width='1' stroke-dasharray='2%2c3' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
    border-radius: 5px;
    width: 1111px;
    /* margin-top: 25px; */
    /* margin-bottom: 10px; */
    justify-content: space-evenly;
}

.ship_package_filter_child_expand {
    display: flex;
    margin-top: 3px;
    margin-bottom: 3px;
    height: 14px;
}

.ship_package_filter_child_expand .ship_package_filter_child_icon_expand {
    display: flex;
    align-items: center;
}

.ship_package_filter_child_expand .ship_package_filter_child_count_expand {
    font-size: 8px;
    display: flex;
    flex-direction: row;
    align-items: center;
    color: #5D5D5D;
    font-family: "SF-Pro-Rounded-light";
    margin-top: 1px;
}

.ship_package_filter_child_expand .ship_package_filter_child_name_expand {
    display: flex;
    font-size: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    color: #5D5D5D;
    font-family: "SF-Pro-Rounded-light";
    margin-left: 6px;
}

.node_parent_expand {
    width: -webkit-fill-available;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.ship_package_filter_child_expand .status_info_info {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.statics_square_area {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    min-height: 70px;
    width: 100%;
    padding: 14px;
    background-color: #F4F5F5;
    background-position: center;
    background-image: url("data:image/svg+xml,%3csvg width='99%25' height='99%25' style='padding-left: 1px;' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='calc(100%25 - 2px)' height='99%25' fill='none' rx='10' ry='10' stroke='%238E8E8EFF' stroke-width='1' stroke-dasharray='2%2c3' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
    border-radius: 10px;
    margin-top: 10px;
}

.statics_square_filters_area {
    padding-left: 6px;
    padding-top: 1px;
    padding-bottom: 1px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: max-content;
}

.statics_square_numbers_area {
    display: flex;
    flex-direction: row;
    min-width: 486px;
    align-items: center;
}

.statics_square_numbers_area_tic {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-right: 20px;
}

.statics_square_numbers_area_amm {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.statics_square_numbers_area_tic_label {
    margin-right: 10px;
    font-family: "SF-Pro-Rounded-light";
    font-size: 16px;
    color: #404040;
    margin-top: 5px;
}

.statics_square_numbers_area_tic_data {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-right: 10px;
    font-family: "SF-Pro-Rounded-regular";
    font-size: 30px;
    color: #404040;
}

.statics_square_numbers_area_tic_data_diff {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-family: "SF-Pro-Rounded-regular";
    font-size: 18px;
    margin-top: 5px;
}

.statics_square_numbers_area_tic_data_diff svg {
    margin-left: 3px;
}

.shipment_main_statuses_with_selected {
    width: -webkit-fill-available;
    display: flex;
    flex-direction: column;
}

.load_more_tickets_container {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.load_more_tickets {
    width: 112px;
    height: 16px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 3px 6px rgb(0, 0, 0, 0.1);
    font-size: 8px;
    color: #5D5D5D;
    cursor: pointer;
    background-color: #cee5ca;
    font-family: "SF-Pro-Rounded-light";
    border-radius: 5px;
}

@keyframes loading_tickets {
    0% {
        background-color: #afcfa9;
    }

    25% {
        background-color: #cee5ca;

    }

    50% {
        background-color: #afcfa9;

    }

    75% {
        background-color: #cee5ca;

    }

    100% {
        background-color: #afcfa9;
    }
}

.load_more_tickets_loading {
    width: 112px;
    height: 16px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 3px 6px rgb(0, 0, 0, 0.1);
    font-size: 8px;
    color: #5D5D5D;
    font-family: "SF-Pro-Rounded-light";
    border-radius: 5px;
    cursor: initial;
    animation-name: loading_tickets;
    animation-duration: 5s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
}