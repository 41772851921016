.contacts-container {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    background-color: #f7f7f7;
    padding: 20px 15px;
    width: 100%;
    animation-name: showUp;
    animation-duration: 0.3s;
    animation-iteration-count: 1;
    border-radius: 10px;
}

.wide-view .contacts-container {
    border-radius: 10px 0px 0px 10px;
}

.contats-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    width: 100%;
}

.contact-header-title {
    display: flex;
    align-items: center;
    height: 20px;
    justify-content: flex-start;
    align-items: center;
}

.contact-header-title span {
    font-size: 14px;
    font-family: "SF-Pro-Rounded";
    color: #404040;
    display: flex;
    margin-left: 10px;
}

.colse-contact {
    display: flex;
}

.contact-filter-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    overflow-x: scroll;
    overflow-y: hidden;
    margin-top: 28px;
    padding-bottom: 9px;
    width: 100% !important;
    min-height: 65px;

}

.contact-filter-item {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding-left: 8px;
    border-radius: 5px;
    background-color: #F0F0F0;
    padding-top: 8px;
    min-width: 77px;
    height: 50px;
}

.contact-filter-item:not(:first-child) {
    margin-left: 10px;
}

.contact-filter-item.active-f {
    background-color: #DDDDDD;
}

.filter-title {
    font-family: "SF-Pro-Rounded-med";
    font-size: 12px;
    color: #5D5D5D;
    display: flex;
    letter-spacing: 0em;
}

.filter-count {
    font-family: "SF-Pro-Rounded";
    font-size: 10px;
    letter-spacing: 0em;

    color: #5D5D5D;
    margin-top: 3px;
    display: flex;
}

.contact-icon {
    display: flex;
    margin-left: 10px;
}

.container-search {
    width: 100% !important;
    margin-top: 10px;
    max-height: 30px;
}

.container-search svg {
    left: 30px !important;
}

.container-search input {
    background-color: #f7f7f7 !important;
    padding-left: 55px;
}

.contact-item {
    width: 100%;
    background-color: #FAFAFA;
    border-radius: 5px;
    min-height: 50px;
    height: auto;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-left: 5px;
    margin-top: 10px;
    padding-right: 10px;
}

.contact-info {
    display: flex;
    min-width: 86%;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    align-content: center;
    flex-wrap: wrap;
}

.contact-img {
    display: flex;
    width: 40px;
    height: 40px;
    border-radius: 5px;
    background-color: #F0F0F0;
}

.contact-img img {
    display: flex;
    min-width: 40px;
    height: 40px;
    border-radius: 5px;
    background-color: #F0F0F0;
}

.contact-detail {
    display: flex;
    flex-direction: column;
    margin-left: 20px;
    align-items: flex-start;
    justify-content: space-between;
    height: 37px;
}

.contact-name {
    letter-spacing: 0em;
    font-size: 12px;
    outline: none;
    border: none;
    color: #5D5D5D;
    font-family: "SF-PRO-Rounded-med";
}

.contact-numb {
    color: #8E8E8E;
    font-size: 12px;
    font-family: "SF-Pro-Rounded";
    letter-spacing: 0em;
}

.contact-comp,
.contact-role {
    display: flex;
    flex-direction: column;
    margin-left: 20px;
    align-items: center;
    justify-content: space-between;
    height: 32px;
}

.contact-filter-item.edit-f:not(:first-child) {
    margin-left: 5px !important;
}

.edit-f {
    min-width: 52px !important;
    max-width: 52px !important;
    padding: 0px !important;
    height: 30px !important;
    justify-content: center !important;
    align-items: center !important;
}

.contact-detail-edit {
    margin-left: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
}

.contacts-list {
    width: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
    display: flex;
    overflow-y: scroll;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
}

.save-contact {
    background-color: #CEFFD0;
    width: 42px;
    height: 22px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 10px;
    font-family: "SF-PRO-Rounded";
    color: #404040;
    border-radius: 5px;
}

.sys_wallet {
    display: flex;
    align-items: center;
    justify-content: center;
    width: -webkit-fill-available;
}

.sys_wallets {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: -webkit-fill-available;
    flex-wrap: wrap;
}

.label-admin {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.reset-contact {
    background-color: #f29595;
    width: 42px;
    height: 22px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 10px;
    font-family: "SF-PRO-Rounded";
    color: #404040;
    border-radius: 5px;
}

.contact-edit {
    display: flex;
    align-items: center;
    cursor: pointer;
    flex-direction: row;
    justify-content: flex-start;
}

.cancel-edit {
    display: flex;
    margin-left: 10px;
    cursor: pointer;
}

.role-edit,
.comp-edit {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.col-res {
    flex-direction: column !important;
    align-items: flex-start;
    margin-left: 5px;
}

.inhj .contact-item .contact-edit {
    display: flex;
    position: absolute;
    right: 10px;
    align-items: center;
    cursor: pointer;
    flex-direction: row;
    justify-content: flex-start;
}

.wide-view .switch-area-plus {
    border-radius: 0px 8px 0px 0px;
}

.switch-area-plus {
    background: #efefef;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    overflow: hidden;
    z-index: 50;
    max-height: 40px;
    min-height: 36px;
}

.switch-area-btn {
    display: inline-block;
    flex: 1;
    padding: 10px;
    position: relative;
    text-align: center;
    transition: background 600ms ease, color 600ms ease;
}

input[type="radio"].toggle {
    display: none;

    &+label {
        cursor: pointer;
        min-width: 60px;

        &:hover {
            background: none;
            color: #1a1a1a;
        }

        &:after {
            background: #1a1a1a;
            content: "";
            height: 100%;
            position: absolute;
            top: 0;
            transition: left 200ms cubic-bezier(0.77, 0, 0.175, 1);
            width: 100%;
            z-index: -1;
            border-radius: 10px;
        }
    }

    &.toggle-left+label {
        border-right: 0;

        &:after {
            left: 100%
        }
    }

    &.toggle-right+label {
        margin-left: -5px;

        &:after {
            left: -100%;
        }
    }

    &:checked+label {
        cursor: default;
        color: #fff;
        transition: color 200ms;

        &:after {
            left: 0;
        }
    }
}

.mode_change_icon {
    background: linear-gradient(to bottom right, #BFFFCB 0%, #b98451 50%, #c7ffd1 100%);
    width: -webkit-fill-available;
    cursor: pointer;
}

.mode_change_icon_active {
    cursor: default;
    background: linear-gradient(to bottom right, #BFFFCB 0%, #8effa2 50%, #c7ffd1 100%);
}

.modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto;
}

.modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto;
}

.modal {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1050;
    display: none;
    width: 100%;
    height: 100%;
    overflow: hidden;
    outline: 0;
}

.fade {
    transition: opacity 0.15s linear;
}

.modal {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1050;
    display: none;
    width: 100%;
    height: 100%;
    overflow: hidden;
    outline: 0;
}

.fade {
    transition: opacity .15s linear;
}

.modal .modal-dialog {
    top: 224px !important;
    right: 29px !important;
    min-width: 400px !important;
    position: absolute !important;
    z-index: 999999 !important;
    -webkit-transform: none;
    transform: none;
}

.modal.fade .modal-dialog {
    transition: -webkit-transform 0.3s ease-out;
    transition: transform 0.3s ease-out;
    transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
    -webkit-transform: translate(0, -50px);
    transform: translate(0, -50px);
}

.modal.show .modal-dialog {
    -webkit-transform: none;
    transform: none;
}

.modal.fade .modal-dialog {
    transition: -webkit-transform .3s ease-out;
    transition: transform .3s ease-out;
    transition: transform .3s ease-out, -webkit-transform .3s ease-out;
    transition: transform .3s ease-out, -webkit-transform .3s ease-out;
    -webkit-transform: translate(0, -50px);
    transform: translate(0, -50px);
}

@media (min-width: 576px) {
    .modal-dialog {
        max-width: 500px;
        margin: 1.75rem auto;
    }
}

.modal-dialog {
    position: relative;
    width: auto;
    margin: 0.5rem;
    pointer-events: none;
}

@media (min-width: 576px) {
    .modal-dialog {
        max-width: 500px;
        margin: 1.75rem auto;
    }
}

.modal-dialog {
    position: relative;
    width: auto;
    margin: 0.5rem;
    pointer-events: none;
}

.modal-content {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    pointer-events: auto;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 0.3rem;
    outline: 0;
}

.modal-content {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    pointer-events: auto;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, .2);
    border-radius: 0.3rem;
    outline: 0;
}

.modal-backdrop.show {
    opacity: 0.5;
}

.modal-backdrop.fade {
    opacity: 0;
}

.modal-backdrop.show {
    opacity: .5;
}

.modal-backdrop.fade {
    opacity: 0;
}

.modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1040;
    width: 100vw;
    height: 100vh;
    background-color: #000;
}

.fade {
    transition: opacity 0.15s linear;
}

.modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1040;
    width: 100vw;
    height: 100vh;
    background-color: #000;
}

.fade {
    transition: opacity .15s linear;
}

.modal-header {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: 1rem 1rem;
    border-bottom: 1px solid #dee2e6;
    border-top-left-radius: calc(0.3rem - 1px);
    border-top-right-radius: calc(0.3rem - 1px);
}

.modal-header {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: 1rem 1rem;
    border-bottom: 1px solid #dee2e6;
    border-top-left-radius: calc(0.3rem - 1px);
    border-top-right-radius: calc(0.3rem - 1px);
}

.modal-title {
    margin-bottom: 0;
    line-height: 1.5;
}

.modal-title {
    margin-bottom: 0;
    line-height: 1.5;
}

.modal-title {
    -webkit-transform: translateY(0px) !important;
    transform: translateY(0px) !important;
}

.modal-title {
    font-size: 12px;
    color: #8E8E8E;
    margin-left: 10px;
    -webkit-transform: translateY(1px);
    transform: translateY(1px);
}

.modal-body {
    position: relative;
    flex: 1 1 auto;
    padding: 1rem;
}

.modal-body {
    position: relative;
    flex: 1 1 auto;
    padding: 1rem;
}

.modal-body {
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    margin-top: 17px;
    flex-direction: row;
}

.modal-body span {
    margin-left: 10px;
    font-size: 16px;
}

.modal-footer {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-end;
    padding: 0.75rem;
    border-top: 1px solid #dee2e6;
    border-bottom-right-radius: calc(0.3rem - 1px);
    border-bottom-left-radius: calc(0.3rem - 1px);
}

.modal-footer {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-end;
    padding: 0.75rem;
    border-top: 1px solid #dee2e6;
    border-bottom-right-radius: calc(0.3rem - 1px);
    border-bottom-left-radius: calc(0.3rem - 1px);
}

.modal-footer {
    width: 100%;
    margin-top: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.btn:not(:disabled):not(.disabled) {
    cursor: pointer;
}

.btn:not(:disabled):not(.disabled) {
    cursor: pointer;
}

.btn:not(:disabled):not(.disabled) {
    cursor: pointer;
}

.btn:not(:disabled):not(.disabled) {
    cursor: pointer;
}

button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
    cursor: pointer;
}

[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled),
button:not(:disabled) {
    cursor: pointer;
}

[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled),
button:not(:disabled) {
    cursor: pointer;
}

button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
    cursor: pointer;
}

.modal-footer>* {
    margin: 0.25rem;
}

.btn-secondary {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d;
}

.btn {
    display: inline-block;
    font-weight: 400;
    color: #212529;
    text-align: center;
    vertical-align: middle;
    -webkit-user-select: none;
    user-select: none;
    background-color: transparent;
    border: 1px solid transparent;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: 0.25rem;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

button,
[type="button"],
[type="reset"],
[type="submit"] {
    -webkit-appearance: button;
}

.modal-footer>* {
    margin: 0.25rem;
}

.btn-secondary {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d;
}

.btn {
    display: inline-block;
    font-weight: 400;
    color: #212529;
    text-align: center;
    vertical-align: middle;
    -webkit-user-select: none;
    user-select: none;
    background-color: transparent;
    border: 1px solid transparent;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: 0.25rem;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

[type=button],
[type=reset],
[type=submit],
button {
    -webkit-appearance: button;
}

.btn {
    display: inline-block;
    font-weight: 400;
    color: #212529;
    text-align: center;
    vertical-align: middle;
    -webkit-user-select: none;
    user-select: none;
    background-color: transparent;
    border: 1px solid transparent;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: 0.25rem;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.btn-secondary {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d;
}

.modal-footer>* {
    margin: 0.25rem;
}

button,
[type="button"],
[type="reset"],
[type="submit"] {
    -webkit-appearance: button;
}

.btn {
    display: inline-block;
    font-weight: 400;
    color: #212529;
    text-align: center;
    vertical-align: middle;
    -webkit-user-select: none;
    user-select: none;
    background-color: transparent;
    border: 1px solid transparent;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: 0.25rem;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.btn-secondary {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d;
}

.modal-footer>* {
    margin: 0.25rem;
}

button,
select {
    text-transform: none;
}

button,
input {
    overflow: visible;
}

.modal-backdrop.show {
    opacity: 0.5;
}

.modal-backdrop.fade {
    opacity: 0;
}

.modal-backdrop.show {
    opacity: .5;
}

.modal-backdrop.fade {
    opacity: 0;
}

.modal-backdrop.fade {
    opacity: 0;
}

.modal-backdrop.show {
    opacity: .5;
}

.modal-backdrop.fade {
    opacity: 0;
}

.modal-backdrop.show {
    opacity: 0.5;
}

.modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1040;
    width: 100vw;
    height: 100vh;
    background-color: #000;
}

.fade {
    transition: opacity 0.15s linear;
}

.modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1040;
    width: 100vw;
    height: 100vh;
    background-color: #000;
}

.fade {
    transition: opacity .15s linear;
}

.fade {
    transition: opacity .15s linear;
}

.modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1040;
    width: 100vw;
    height: 100vh;
    background-color: #000;
}

.fade {
    transition: opacity 0.15s linear;
}

.modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1040;
    width: 100vw;
    height: 100vh;
    background-color: #000;
}

.fade {
    transition: opacity .15s linear;
}

.fade {
    transition: opacity 0.15s linear;
}

.modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto;
}

.modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto;
}

.modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto;
}

.modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto;
}

.modal {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1050;
    display: none;
    width: 100%;
    height: 100%;
    overflow: hidden;
    outline: 0;
}

.fade {
    transition: opacity 0.15s linear;
}

.modal {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1050;
    display: none;
    width: 100%;
    height: 100%;
    overflow: hidden;
    outline: 0;
}

.fade {
    transition: opacity .15s linear;
}

.fade {
    transition: opacity 0.15s linear;
}

.fade {
    transition: opacity .15s linear;
}

.fade {
    transition: opacity 0.15s linear;
}

.fade {
    transition: opacity .15s linear;
}

.fade {
    transition: opacity .15s linear;
}

.fade {
    transition: opacity 0.15s linear;
}

.fade {
    transition: opacity .15s linear;
}

.fade {
    transition: opacity 0.15s linear;
}

.fade {
    transition: opacity .15s linear;
}

.modal {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1050;
    display: none;
    width: 100%;
    height: 100%;
    overflow: hidden;
    outline: 0;
}

.fade {
    transition: opacity 0.15s linear;
}

.modal {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1050;
    display: none;
    width: 100%;
    height: 100%;
    overflow: hidden;
    outline: 0;
}