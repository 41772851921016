.lang-name {
    margin-left: 10px !important;
}

.department .icon-lang {

    cursor: pointer;
}

svg.activated-hrm path {
    fill: #404040;
}

.pure.parent-icon.outline {
    background-color: transparent !important;
}

.department .accent.pure.parent-icon.outline {
    width: 23px;
}

.pure.parent-icon.outline svg path {
    fill: #5D5D5D;
}

.parent-info:hover .pure.parent-icon.outline svg path {
    fill: #404040;
}

.parent-info.accent .text.parent-icon.outline {
    width: 23px;
    border-radius: 2px;
}

.follower,
.follower-list {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}

input.pad20 {
    padding-left: 20px;
}

.pa .lang-input-icon {
    left: 12px !important;

}

.lang-input-icon {
    position: absolute;
    top: 2px;
    left: 2px;
}

.lang-input-icon text tspan {
    font-size: 10px !important;
}

.thir {
    width: 70px !important;
    margin-left: 0px !important;

}

.modal-top-title .text-svg g g rect {
    fill: none !important;
}

.mar {
    margin-left: 5px !important;
}

.lang-modal {
    display: none !important;
}

.lang-modal.open {
    padding-bottom: 20px;
    height: auto;
    display: block !important;
}

.lang-body .parent-info .parent-icon.pure.active-lang {
    background-color: transparent !important;
}

.pure.parent-icon.outline {
    background-color: transparent !important;
}

.modal-top-title svg #add-3 {
    fill: #404040 !important;
}

.short-input-element input.pad20:read-only {
    color: #8e8e8e !important;
}

div.activated>.pure.parent-icon.outline svg path {
    fill: #404040;
}

.department .accent .pure.parent-icon.outline {
    background-color: transparent !important;
}

.accent .department .accent .pure.parent-icon.outline {
    background-color: transparent !important;
}

.mar img {
    margin-left: 0px !important;
}

.department .parent-info .parent-icon.pure {
    width: 23px !important;
}

.department .parent-info .parent-icon.pure svg {
    width: 23px !important;
}

.expand-cont {
    z-index: 400 !important;
}

.follower-list {
    transform: translateY(0px) !important;
}

.parent-icon.wid23 {
    width: 23px !important;
}

.parent-icon.wid23 img {
    width: 23px !important;
}

.lang-body .parent-info .parent-name {
    transform: translateY(0px) !important;
}

.lang-body .parent-info .parent-icon.pu {
    background-color: transparent !important;
}

.lang-body .pu .parent-icon {
    background-color: transparent !important;

}

.lang-body .parent-info .dept.parent-icon {
    width: auto !important;
    background-color: transparent !important;
}

.error-icon {
    z-index: 9999;
}

.error-icon:hover~.error-cont {
    opacity: 1;
    height: 100px;
    padding: 20px;
}

.error-icon:hover~.error-cont .text-error {
    opacity: 1;
    transition: 0s;
    transition-delay: 1s;
}

.error-cont {
    transition: 0.8s;
    width: 100px;
    opacity: 0;
    top: 18px;
    left: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 0px;
    transition-timing-function: ease-in;
    border-radius: 10px 20px;
    width: 200px;
    padding: 0px;
    background-color: #f8aab3;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    color: #5D5D5D;
    position: absolute;
}

.error-cont .text-error {
    opacity: 0;
    transition: 0s;
    transition-delay: 0s;

}

#CFO {
    font-size: 8px;
    font-family: "SF-Pro-Rounded";
}

.status-container~.parent-icon,
.status-container~.parent-row {
    filter: opacity(0.6);
}

.modal-top-title svg.emp-dept:first-child g rect {
    fill: transparent !important;
}

input:read-only {
    font-weight: lighter !important;
}