@import url(./App.darktheme.css);

.call_log {
    width: auto !important;
    max-width: 50% !important;
    min-width: 40px !important;
    border-radius: 7px !important;
    background-color: #ffc9c9;
    padding: 7px !important;
    font-size: 12px !important;
    min-height: 12px !important;
    line-height: 12px !important;
    width: auto !important;
    max-width: 50% !important;
    min-width: 40px !important;
    color: #7c7979 !important;
    align-items: center !important;
}

.call_log_container {
    width: 100% !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
}

.BIG_tag {
    font-weight: bolder !important;

}


.absulute_currency {
    position: absolute;
    left: 33px;
    bottom: 13px;
    font-size: 12px;
    color: #404040;
    font-family: "SF-Pro-Rounded-light";
}

.app * {
    /* font-family: -apple-system, BlinkMacSystemFont, 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif; */
    -webkit-font-smoothing: antialiased;
    font-family: Segoe UI, SF-Pro-Rounded;
    -moz-osx-font-smoothing: grayscale;
    letter-spacing: 0.0em !important;
}

.app {
    /* font-family: -apple-system, BlinkMacSystemFont, 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif; */
    font-family: Segoe UI, SF-Pro-Rounded;
    width: 100%;
    height: calc(100%);
    max-height: 919px;

    position: relative;
    letter-spacing: 0.0em !important;
    border-radius: 10px;
}


.app__mobile-message {
    display: none;
}

.dark-theme .app-content {
    background-color: #131c21;
}

.app-content {
    width: 100%;
    overflow: hidden;
    justify-content: flex-end;
    height: calc(100vh - 100px);
    margin: 0 auto;
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, .06), 0 2px 5px 0 rgba(0, 0, 0, .2);
    /* position: relative; */
    z-index: 100;
    display: flex;
    overflow: hidden;
    align-items: flex-start;
    border-radius: 10px;
}

.chat__input-wrapper {
    /*border-top-color: rgb(36, 45, 50);*/
    /*background: rgb(19, 28, 33);*/
}

@media screen and (max-width: 500px) {
    .app__mobile-message {
        padding-top: 200px;
        text-align: center;
        font-size: 1.2rem;
        display: block;
    }

    .app-content {
        display: none;
    }
}

@media screen and (max-width:1100px) {
    .change-view {
        display: none !important;
    }
}

/* @media screen and (min-height: 1200px) {
    .app {
        height: 919px;
    }

    .app-content {
        height: 919px;
    }
} */

#basic-menu {
    z-index: 999999999;
}

.options-btn {
    width: 25px;
    height: 25px;
    pointer-events: visible;
    user-select: auto;
    z-index: 9999;
}

.MuiList-root.MuiMenu-list.MuiList-padding {
    z-index: 999999999;
}

.abs-menu {
    bottom: -45px;
    padding-top: 5px;
    display: none;
    opacity: 0;
    z-index: 9999999;
    transition: all 0.4s ease-in-out;
    left: 0px;
    position: static;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    animation-name: slideInMenu;
    animation-duration: 0.5s;
    animation-direction: normal;
}

@keyframes slideInMenu {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.reply-but {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 35px;
    cursor: pointer;
    transition: all 0.4s ease-in-out;

    height: 40px;
    border-radius: 12px;
    background-color: #FAFAFA;
    box-shadow: rgba(0, 0, 0, 0.167) 0px 2px 10px;
}

.rep-descs {
    position: absolute;
    bottom: -45px;
    white-space: nowrap;
    width: auto;
    left: -4px;
    align-items: center;
    transition: all 0.4s ease-in-out;
    justify-content: center;
    display: none;
    border-radius: 5px;
    background-color: #404040;
    padding: 4px 10px;
    font-family: "SF-Pro-Rounded";
    font-size: 10px;
    color: #FFFFFF;
}

.reply-but:hover .rep-descs {
    display: flex;
}

.message-ops {
    display: flex;
    flex-direction: row;
    transition: all 0.4s ease-in-out;
    align-items: center;
    justify-content: space-between;
    margin-left: 5px;
    background-color: #FAFAFA;
    box-shadow: rgba(0, 0, 0, 0.167) 0px 2px 10px;
    border-radius: 12px;
    width: 185px;
    height: 40px;
    padding: 13px 10px;
}

.message-opt {
    display: flex;
    transition: all 0.4s ease-in-out;
    align-items: center;
    justify-content: center;
    position: relative;
    cursor: pointer;
}

.message-opt:hover .rep-descs {
    display: flex;
}

.message-hold.ac .abs-menu,
.message-hold.ac .abs-menu {
    opacity: 1;
    display: flex;
}

.ref-bot {
    margin-top: 7px;
}

.self-align .message-hold .message-body,
.self-align .message-hold .abs-menu {

    align-self: flex-end;
}

.message-hold:hover .message-body {
    min-width: 246px;
}

.abs-menu-cha {
    display: flex;
    left: 23px;
    width: 48%;
    align-items: center;
    justify-content: space-between;

}

.abs-menu-cha.inhj {
    display: flex;
    left: 23px;
    width: 100%;
    align-items: center;
    justify-content: space-between;
}

.inhj .contact-item {
    background-color: transparent !important;
    margin: 0px !important;
    justify-content: center !important;
    position: relative;
}

.inhj .contact-item .contact-info .contact-detail {
    margin: 0px !important;
    align-items: center !important;
}

.inhj.low .contact-info {
    flex-direction: column !important;
}

.inhj.low .contact-info .contact-detail {
    width: 100%;
}

.inhj .contact-item .contact-info .contact-detail-edit.col-res {
    margin-top: 10px;
}

.inhj .contact-item .contact-info .contact-detail-edit.col-res .role-edit {
    margin-top: 10px;
}

.inhj .contact-item .contact-info .contact-detail .contact-name {
    flex: 1 1;
    color: #000000;
    font-size: 16px;

}

.inhj .contact-item .contact-info .contact-detail .contact-numb {
    color: #8E8E8E;
    font-size: 14px;
    font-family: "SF-Pro-Rounded";
    letter-spacing: 0em;

}

.ticket_open_close_approved {
    border: 0.5px solid #09DE00;
}

.ticket_open_close_tron {
    border: 0.5px solid #de7600;
}

.ticket_open_close_waiting {
    border: 0.5px solid #4A71F2;
}

.ticket_open_close_rejected {
    border: 0.5px solid #FF7171;
}

.mr-w {
    margin-right: 10px;
}

.inhj .contact-info {
    align-items: flex-start !important;
    flex-direction: column;
}

.company-name,
.role-name {
    display: flex;
    width: auto;
    padding: 10px;
    letter-spacing: 0.04em !important;
    align-items: center;
    justify-content: center;
    border: #5d5d5d 1px solid;
    border-radius: 5px;
}

.edit-conta {
    display: flex;
    width: 114px;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 10px;
}

.statics_element_filter {
    margin-top: 10px;
    margin-left: 20px;
    margin-right: 20px;
    width: 330px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.tickets_element_filter {
    width: 287px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.tickets_element_filter_button {
    background-color: #FAFAFA;
    border-radius: 10px;
    background-position: center;
    background-image: url("data:image/svg+xml,%3csvg width='99%25' height='99%25' style='padding-left: 1px;' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='calc(100%25 - 2px)' height='99%25' fill='none' rx='10' ry='10' stroke='%238E8E8EFF' stroke-width='1' stroke-dasharray='2%2c3' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
    border-radius: 10px;
    color: #404040;
    padding: 5px;
    padding-left: 10px;
    padding-right: 10px;
    cursor: pointer;
    font-size: 10px;
    font-family: SF-PRO-Rounded_light;
}

.tickets_element_filter_button:hover {
    background-color: #E3FDED;
    border-radius: 10px;
    background-position: center;
    background-image: url("data:image/svg+xml,%3csvg width='99%25' height='99%25' style='padding-left: 1px;' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='calc(100%25 - 2px)' height='99%25' fill='none' rx='10' ry='10' stroke='%238E8E8EFF' stroke-width='1' stroke-dasharray='2%2c3' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
    border-radius: 10px;
    color: #404040;
    padding: 5px;
    padding-left: 10px;
    padding-right: 10px;
    cursor: pointer;
    font-size: 10px;
    font-family: SF-Pro-Rounded;
}

.tickets_element_filter_button_active {
    background-color: #E3FDED;
    border-radius: 10px;
    background-position: center;
    background-image: url("data:image/svg+xml,%3csvg width='99%25' height='99%25' style='padding-left: 1px;' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='calc(100%25 - 2px)' height='99%25' fill='none' rx='10' ry='10' stroke='%238E8E8EFF' stroke-width='1' stroke-dasharray='2%2c3' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
    border-radius: 10px;
    color: #404040;
    padding: 5px;
    padding-left: 10px;
    padding-right: 10px;
    cursor: pointer;
    font-size: 10px;
    font-family: SF-Pro-Rounded;
}

.dark-theme .statics_element_filter_button_active {
    background-color: #FAFAFA;
    border: 0.5px solid #FAFAFA;
    color: #404040;
}

.statics_element_filter_button_active {
    background-color: #404040;
    border-radius: 10px;
    border: 0.5px solid #404040;
    color: #FAFAFA;
    padding: 5px;
    padding-left: 10px;
    padding-right: 10px;
    cursor: pointer;
}

.dark-theme .statics_element_filter_button {
    background-color: #2a2f32;
    border: 0.5px solid #fafafa;
    color: #fafafa;
}

.statics_element_filter_button {
    background-color: #FAFAFA;
    border-radius: 10px;
    border: 0.5px solid #404040;
    color: #404040;
    padding: 5px;
    padding-left: 10px;
    padding-right: 10px;
    cursor: pointer;

}

.dark-theme .statics_element_filter_button:hover {
    background-color: #FAFAFA;
    border: 0.5px solid #FAFAFA;
    color: #404040;
}

.statics_element_filter_button:hover {
    background-color: #404040;
    border-radius: 10px;
    border: 0.5px solid #404040;
    color: #FAFAFA;
    padding: 5px;
    padding-left: 10px;
    padding-right: 10px;
}

.dark-theme .statics_element {
    background-color: #2a2f32;
}

.statics_element {
    background-color: #404040;
    border-radius: 10px;
    margin-top: 10px;
    margin-left: 20px;
    margin-right: 20px;
    min-width: 388px;
    min-height: 72px;
    width: -webkit-fill-available;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 10px;
    padding-right: 20px;
}

.statics_element_2 {
    margin-top: 5px;
    margin-right: 20px;
    margin-left: 20px;
    min-width: 388px;
    min-height: 65px;
    width: -webkit-fill-available;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
    z-index: 999;
}

.recieved_tickets_title {
    font-size: 14px;
    color: #404040;
    word-break: keep-all;
    min-width: 95px;
    font-family: SF-PRO-Rounded_light;
}

.ghabasheh {
    position: absolute;
    height: 100%;
    min-width: 445px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background: #e8eced40;
    z-index: 9999999999999999999;
}

.ghabasheh #startup {
    width: 200px;
    height: 200px;
}


.recieved_tickets_data {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 7px;
    align-items: center;
}

.recieved_tickets_data_1 {
    font-size: 16px;
    color: #404040;
    font-family: SF-Pro-Rounded;
}

.rejected_tickets_closed .recieved_tickets_title {
    min-width: 0px;
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: center;
}

.rejected_tickets_closed .recieved_tickets_data {
    justify-content: center;
}

.recieved_tickets_data_2 {
    font-size: 16px;
    color: #404040;
    font-weight: 600;
    font-family: SF-Pro-Rounded;
}

.dark-theme .waiting_tickets {
    background-color: #2a2f32;
}

.waiting_tickets {
    background-color: #F0F4FF;
    border-radius: 10px;
    padding: 10px;
    width: -webkit-fill-available;
    height: 65px;
    cursor: pointer;
    margin-left: 5px;
}

.dark-theme .waiting_tickets_active {
    border: 0.5px solid #4A71F2;
    background-color: #2a2f32;
}

.waiting_tickets_active {
    background-color: #F0F4FF;
    border-radius: 10px;
    padding: 10px;
    width: -webkit-fill-available;
    height: 65px;
    box-shadow: 0px 3px 6px rgb(0, 0, 0, 0.16);
    cursor: pointer;
    margin-left: 5px;
}

.dark-theme .waiting_tickets_closed {
    border: 0.5px solid #4A71F2;
    background-color: #2a2f32;
}

.waiting_tickets_closed {
    background-color: #F0F4FF;
    border-radius: 10px;
    padding: 10px;
    width: -webkit-fill-available;
    height: 65px;
    box-shadow: 0px 3px 6px rgb(0, 0, 0, 0.16);
    cursor: pointer;
    max-width: 93px;
    margin-left: 5px;
}

.dark-theme .recieved_tickets {
    background-color: #2a2f32;
}

.recieved_tickets {
    background-color: #F0FFF5;
    border-radius: 10px;
    padding: 10px;
    width: -webkit-fill-available;
    height: 65px;
    cursor: pointer;
}

.dark-theme .recieved_tickets_data_1 {
    color: #FAFAFA;
}

.dark-theme .recieved_tickets_data_2 {
    color: #FAFAFA;
}

.dark-theme .recieved_tickets_title {
    color: #FAFAFA;
}

.dark-theme .recieved_tickets_closed {
    border: 0.5px solid #09DE00;
    background-color: #2a2f32;
}

.recieved_tickets_closed {
    background-color: #F0FFF5;
    border-radius: 10px;
    padding: 10px;
    width: -webkit-fill-available;
    height: 65px;
    box-shadow: 0px 3px 6px rgb(0, 0, 0, 0.16);
    cursor: pointer;
}

.dark-theme .rejected_tickets {
    background-color: #2a2f32;
}

.rejected_tickets {
    background-color: #FDEAEA;
    border-radius: 10px;
    padding: 10px;
    width: -webkit-fill-available;
    margin-left: 5px;
    height: 65px;
    cursor: pointer
}

.dark-theme .rejected_tickets_closed {
    border: 0.5px solid #FF7171;
    background-color: #2a2f32;
}

.rejected_tickets_closed {
    background-color: #FDEAEA;
    border-radius: 10px;
    padding: 10px;
    width: -webkit-fill-available;
    margin-left: 5px;
    height: 65px;
    box-shadow: 0px 3px 6px rgb(0, 0, 0, 0.16);
    cursor: pointer;
    max-width: 68px;
}

.ticket_and_total {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: -webkit-fill-available;
}

.ticket_and_total_data {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: -webkit-fill-available;
}

.dark-theme .ticket_and_total1 {
    color: #FAFAFA;
}

.ticket_and_total1 {
    font-size: 14px;
    color: #FAFAFA;
    font-weight: 200;
}

.dark-theme .ticket_and_total2 {
    color: #FAFAFA;
}

.ticket_and_total2 {
    font-size: 14px;
    color: #FAFAFA;
    font-weight: 200;
}

.dark-theme .ticket_and_total_data2 {
    color: #FAFAFA;
}

.ticket_and_total_data2 {
    font-size: 16px;
    color: #FAFAFA;
    font-weight: 800;
}

.dark-theme .ticket_and_total_data1 {
    color: #FAFAFA;
}

.ticket_and_total_data1 {
    font-size: 16px;
    color: #FAFAFA;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.dark-theme .ticket_and_total_data1_approved {
    color: #08c042;
}

.ticket_and_total_data1_approved {
    font-size: 20px;
    color: #8EFFB1;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-right: 10px;
}

.dark-theme .ticket_and_total_data1_rejected {
    color: #f93838;
}

.ticket_and_total_data1_rejected {
    font-size: 20px;
    color: #FE8E8E;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: 10px;
}

.top_options_tickets_count {
    display: flex;
    flex-direction: row;
    margin-right: 20px;
    align-items: center;
}

.top_options_tickets_count_text {
    color: #5D5C5D;
    font-size: 16px;
    margin-right: 14px;
}

.inner_chats_section {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: calc(100vh - 300px);
    width: -webkit-fill-available;
    overflow-y: scroll;
    padding-left: 10px;
    padding-right: 5px;
}

.load_more_chats {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 3px 6px rgb(0, 0, 0, 0.1);
    font-size: 8px;
    color: #404040;
    cursor: pointer;
    background-color: #cee5ca;
    font-family: SF-Pro-Rounded-light;
    font-size: 14px;
    border-radius: 10px;
    padding: 8px;
    margin: 5px;
}

.load_more_chats:hover {
    background-color: #abe0a1;
}

.aside_ticket_modal {
    height: calc(100vh - 100px);
    min-width: 450px;
    max-width: 450px;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-between;
    z-index: 16;
    background-color: #F7F7F7;
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, .06), 0 2px 5px 0 rgba(0, 0, 0, .2);
    border-radius: 10px;
}

.aside_ticket_modal_top {
    min-height: 60px;
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    border-bottom: 0.5px solid #CECDCD;
    padding-left: 20px;
    padding-right: 20px;
}

.aside_ticket_modal_body {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: 17px;
    flex-direction: column !important;
    height: 100%;
    width: 100%;
    padding-right: 20px;
    padding-left: 20px;
    overflow-y: scroll;
    overflow-x: hidden;
}

.aside_ticket_modal_body_request_type {
    width: 100%;
}

.drafts {
    background-color: #CECDCD !important;
    width: 64px !important;
    color: #404040 !important;
    margin-right: 6px !important;
    font-size: 10px !important;
    font-family: "SF-Pro-Rounded";
    box-shadow: none !important;
    text-transform: capitalize;
}

.modal-top-title .no-dis path {
    fill: #404040 !important;
}

.mar.cityflag {
    height: auto;
    width: 25%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}

.mar.cityflag:not(:first-child) {
    margin-left: 9.5px;
}

.req_type {
    margin-top: 2px;
    background-color: #404040;
    border-radius: 5px;
    color: #fff;
    font-size: 10px;
    font-family: "SF-Pro-Rounded";
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 4px;
    padding-top: 4px;
    max-height: 22px;
    width: fit-content;
    margin-top: 3px;
}

.modal-icon-name {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}

.modal-name-icon {
    margin-left: 5px;
    font-family: "SF-Pro-Rounded";
    font-size: 10px;

}

.pad35 {
    padding-left: 44px !important;
}

.abs-icon {
    position: absolute;
    left: 15.5px;
}

.cal {}

.input-holder {
    position: relative;
    transition: 0.3s;
}

.trf .input-holder input {
    height: 44px;
    display: flex;
    padding-top: 6px;
    align-items: center;
}

.input-holder input::placeholder {
    color: #DDDDDD;
    font-size: 12px;
    font-family: "SF-Pro-Rounded";
}

.input-holder img {
    position: absolute;
    right: 0px;
    bottom: 12px;
    z-index: 999999;
}

.fill-norm path {
    fill: #404040;
}

.icons-ol {
    height: 44px;
}

.holder-option {
    width: 33.3%;
    height: 100%;
    display: flex;
    align-items: center;
    cursor: pointer;
    justify-content: center;
}

.holder-option svg {
    position: static !important;
}

.option-desc {
    margin-left: 9px;
    background-color: #404040;
    border-radius: 5px;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 4px;
    padding-top: 4px;
    font-size: 10px;
    font-family: "SF-Pro-Rounded";
    color: #FFFFFF;
    max-height: 22px;
}

.holder-option svg:not(.fill-norm) path {
    fill: #5d5d5d;
}

.modal-footer {
    width: 100%;
    margin-top: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal-button-transfer {
    font-size: 10px;
    font-family: "SF-Pro-Rounded-med";
    background-color: #5674F0;
    border-radius: 10px;
    cursor: pointer;
    color: #FFFFFF;
    padding: 14px 20px;
}

.modal-button-transfer.disable {
    background-color: #AFBFFD;
}

.modal-icons.trf {
    align-items: flex-start !important;
}

.trf .input-section {
    margin-top: 0px !important;
}

input {
    z-index: 888;
}

.pl,
img {
    cursor: pointer;
}

.amoun input {
    font-family: "SF-Pro-Rounded-med" !important;
}

.input-sync {
    display: flex;
    width: 100%;
    height: auto;
    left: 0px;
    top: 34px;
    position: absolute;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    z-index: 9999 !important;
    cursor: pointer;
    background-color: #FAFAFA;
    max-height: 200px;
    overflow-y: visible;
}

.input-sync-item svg,
.input-sync-item img {
    position: static !important;
}

.input-sync-item:hover {
    background-color: #c2c2c2;
}

.input-sync-item {
    width: 100%;
    margin-top: 2px;
    transition: 0.3s;
    padding: 12px 15px;
    background-color: #DDDDDD;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
}

.input-sync-text {
    font-family: "SF-Pro-Rounded";
    font-size: 14px;
    color: #404040;
    margin-left: 10px;
    text-transform: capitalize;
}

.data-country {
    position: absolute;
    left: 15px;
    bottom: 11px !important;
}

img.data-country {
    bottom: 8px !important;
    border-radius: 3px;
    object-fit: cover;
}

.pad48 input {
    padding-left: 47px !important;
}

.input-sync-item img {
    border-radius: 3px;
    object-fit: cover;

}

input.cal#caland {
    right: 0 !important;
    left: initial !important;
    width: 20%;
    transform: none !important;
    position: absolute;
    padding: 0px !important;
}

input.cal:focus {}

svg {
    transition: 0.3s;

}

svg path {
    transition: 0.3s;
}

svg.red-bord path {
    fill: #EA4343;
}

.lang-modal {
    box-shadow: rgba(0, 0, 0, 0.1) 0px 3px 20px;
    border: rgba(221, 221, 221, 0.52) 0.5px solid;
}

input#session-date::-webkit-calendar-picker-indicator {
    display: block;
    top: 0;
    left: 0;
    background: #0000;
    position: absolute;
    transform: scale(12)
}

input::-webkit-date-and-time-value {
    text-align: right;
}

.spin-cont {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f5f3f3 !important;
}

.spin-cont.no-tran {
    display: flex;
    min-width: 100%;
    align-items: center;
    justify-content: center;
    background-color: transparent !important;
}

.contact-method {
    display: flex;
    align-items: center;
    position: absolute;
    top: 19px;
    right: 25.5px;
}

.contact-method svg {
    position: static !important;
    cursor: pointer;
}

.contact-method svg:not(:last-child) {
    margin-right: 10px;
}

.plus-minus {
    position: absolute;
    top: 6px;
    right: -3px;
}

.modal-icon-section.trf-modal {
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.second-text {
    margin-left: 9px;
    font-size: 10px;
    color: #CECDCD;
    font-family: "SF-PRO-Rounded_light";
}

.sys_lang {
    transition: 0.4s;
    left: -35px;
    position: absolute;
}

.active_sys.sys_lang path {
    fill: #4888EF;
}

.loading-image {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    border-radius: 10px;
    background-color: #FFFFFF;
}

.lang-body .parent-info .flat.parent-icon {
    background-color: transparent !important;
}

div.activated.parent-info>.parent-icon.flat svg rect {
    fill: none !important;
}

.aside_ticket_modal_body_req {
    margin-right: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.input-holder input .pad20 {
    padding-left: 35px !important;
}

.absolute_input_icon {
    position: absolute;
    left: 15px;
}

.absolute_flag_country_ticket {
    position: absolute;
    font-family: "Noto Emoji" !important;
    z-index: 999;
    left: 5px;
    font-size: 10px;
}

.relative_flag_country_ticket {
    font-family: "Noto Emoji" !important;
    font-size: 10px;
}

.ticket_modal_plus_icon {}

.modal_input_with_select_selected_item {
    background-color: #dddddd !important;
    border-radius: 10px 10px 0px 0px;
}

.input-sync::-webkit-scrollbar {
    display: none;
}

.drop_zone_files_ticket {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    margin-top: 10px;
    margin-bottom: 10px;
    flex-wrap: wrap;
    max-width: 404px;
}

.drop_zone_files_ticket_image {
    margin-right: 10px;
    margin-bottom: 10px;
    border-width: 2px;
    border-radius: 2px;
    border-color: rgb(142, 142, 142);
    border-style: dashed;
    outline: none;
    min-width: 50px;
    min-height: 70px;
    max-width: 50px;
    max-height: 70px;
}

.select_item_commision_type {
    width: fit-content;
    padding: 5px;
    border-radius: 5px;
    background-color: #404040;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin: 5px;
}

.select_item_commision_type_selected {
    width: fit-content;
    padding: 5px;
    border-radius: 5px;
    background-color: #dddddd;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 5px;
}

.select_item_commision_type_text {
    color: #FAFAFA;
    font-family: "SF-Pro-Rounded-med";
    font-size: 10px;
}

.select_item_commision_type_text_selected {
    color: #404040;
    font-family: "SF-Pro-Rounded-med";
    font-size: 10px;
}

.select_area_commision_type {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 5px;
}

.multi_inputs_one_raw {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
}

.multi_inputs_one_raw .input-section:not(:last-child) {
    margin-right: 20px;
}

.input_section_amount {
    margin-top: 5px;
    display: flex;
    width: 35%;
    align-items: center;
    flex-direction: column;
    justify-content: flex-start;
}

.input_section_amount input {
    outline: none;
    border: none;
    background: transparent;
    font-size: 12px;
    color: #404040;
    text-align: start;
    height: 39px;
    width: 100%;
    padding-left: 24px;
    border-bottom: #CECDCD 0.5px solid;
    font-family: "SF-PRO-Rounded-med";
}