.chat-icon {
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 20px;
}

.chat-modal {
    height: calc(100% - 100px) !important;
    top: 90px;
    right: 30px;
    width: 425px;

    position: absolute !important;
    overflow: hidden;
    max-height: 100%;
    border-radius: 10px;
    background-color: #F7F7F7;

    max-height: 919px;
}

.loader {
    background: rgb(240, 240, 240);
    min-height: 100vh;
    width: 100%;
}

@keyframes showUp {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.popup_emp_name_out {
    position: absolute;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: #404040;
    border-radius: 5px;
    color: #FAFAFA;
    font-family: "SF-Pro-Rounded";
    font-size: 10px;
    padding: 3px 6px 3px 6px;
    width: max-content;
    /* cursor: pointer; */
    margin-right: 10px;
    -webkit-animation-name: showUp;
    animation-name: showUp;
    -webkit-animation-duration: 0.5s;
    animation-duration: 0.5s;
    -webkit-animation-iteration-count: 1;
    animation-iteration-count: 1;
    right: 0px;
    bottom: -25px;
}

.dark-theme .popup_emp_name {
    background-color: #FAFAFA;
    border-radius: 5px;
    color: #404040;
}

.popup_emp_name {
    position: absolute;
    top: -24px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: #404040;
    border-radius: 5px;
    color: #FAFAFA;
    font-family: "SF-Pro-Rounded";
    font-size: 10px;
    padding: 2px 4px 2px 4px;
    width: max-content;
    /* cursor: pointer; */
    margin-right: 10px;
    -webkit-animation-name: showUp;
    animation-name: showUp;
    -webkit-animation-duration: 0.5s;
    animation-duration: 0.5s;
    -webkit-animation-iteration-count: 1;
    animation-iteration-count: 1;
    left: -24px;
}

.loader_dots {
    overflow: hidden;
    width: 50px;
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    z-index: 100000;
    margin-left: 10px;
}

.loader_dots__element {
    border-radius: 100%;
    border: 3px solid #555;
    margin: 5px;
}

.loader_dots__element:nth-child(1) {
    animation-name: preloader;
    animation-duration: 0.6s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
    animation-direction: alternate;
}

.loader_dots__element:nth-child(2) {
    animation-name: preloader;
    animation-duration: 0.6s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
    animation-direction: alternate;
    animation-delay: 0.2s;
}

.loader_dots__element:nth-child(3) {
    animation-name: preloader;
    animation-duration: 0.6s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
    animation-direction: alternate;
    animation-delay: 0.4s;
}

@keyframes preloader {
    100% {
        transform: scale(2);
    }
}

.whats-modal {
    height: calc(100% - 100px);
    top: 90px;
    right: 30px;
    min-width: 450px;
    padding: 10px 4px;
    position: absolute !important;
    overflow: hidden;
    max-height: 100%;
    transition: none !important;
    z-index: 9999999999;
}

.whats-modal.lang-modal.open {
    display: flex !important;
    overflow: visible;
    justify-content: flex-start;
}

.whats-modal.open {
    display: flex !important;
    overflow: visible;
    justify-content: flex-start;
}

.chats-container::-webkit-scrollbar-thumb {
    background-color: #DDDDDD;
    border-radius: 5px;
}

.chats-container::-webkit-scrollbar {
    width: 8px;

}

.account-cont {
    position: absolute;
}

.chat-icon:hover .chat-top-icons {
    right: 0px;
}

.chat-top-icons {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    top: -16px;
    flex-direction: row-reverse;
    right: 0px;
    width: auto;
    position: absolute;
    height: 40px;
}

.chat-main {
    display: flex;
    transition: all 0.4s ease;

    width: 100%;
    height: 100%;
    flex-direction: column;
    position: absolute;
}

.chat-avatar {

    top: 0px;
    position: relative;
    margin-right: 3px;
    width: 15px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px;
    display: flex;
    height: 15px;
    border-radius: 5px;
    left: 0px;
}

.wa-icons .chat-avatar {
    min-width: 15px;
    min-height: 15px;
}

.chat-icon:hover .chat-top-icons.wa-icons {
    top: -35px;
}

.chat-icon:hover .chat-top-icons.wa-icons .chat-avatar {
    width: 30px;
    min-width: 30px;
    height: 30px;
    position: relative;
    left: 0px !important;
    margin-left: 3px;
}

.chat-avatar:first-child {
    margin-right: 0px;
}

.chat-avatar::before {
    content: "";
    box-shadow: inset rgba(255, 255, 255, 0.5) 0px 3px 6px;
    position: absolute;
    top: 0px;
    left: 0px;
    cursor: pointer;
    width: 15px;
    z-index: 888;
    height: 15px;
}

.chat-avatar img {
    width: 15px;
    height: 15px;
    z-index: 88;
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px;

}

.points-cont {
    width: 47px;
    display: flex;
    align-content: center;
    align-items: center;
    cursor: pointer;
    position: absolute;
    justify-content: space-between;
    bottom: 7px;
    left: -16px;
}

.chat-icon:hover .chat-top-icons .chat-avatar {
    width: 30px;
    height: 30px;
    position: relative;
    left: 0px !important;
    margin-left: 3px;
}

.chat-icon:hover .chat-top-icons .chat-avatar::before {
    width: 30px;
    height: 30px;

}

.chat-icon:hover .chat-top-icons .chat-avatar img {
    width: 30px;
    height: 30px;

}

.chat-icon:hover .chat-top-icons {

    top: -38px;
}

.chat-avatar:hover {
    transform: scale(1.1);
}

.chat-main-top {
    width: 100%;
    display: flex;
    align-content: center;
    align-items: center;
    padding-left: 16px;
    padding-right: 16px;
    justify-content: flex-start;
    position: absolute;
    top: 10px;
    z-index: 999;

}

.user-avatar {
    display: flex;
    position: relative;
    width: 40px;
    height: 40px;
}

.user-avatar::before {
    box-shadow: inset rgba(255, 255, 255, 0.5) 0px 3px 6px;
    content: "";
    position: absolute;
    width: 40px;
    height: 40px;
    top: 0px;
    left: 0px;
}

.user-avatar img {
    width: 40px;
    border-radius: 10px;
    height: 40px;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 6px;

}

.user-name-avt {
    display: flex;
    margin-left: 10px;
    font-size: 14px;
    color: #5D5D5D;
    letter-spacing: 0.01em;
    font-family: "SF-Pro-Rounded-med";
}

div.search {
    position: absolute;
    top: 40px;
    z-index: 999;
    width: 100%;
    padding-left: 16px;
    padding-right: 16px;
    height: 30px;
    margin-top: 25px;
    display: flex;
}

.search svg {
    position: absolute;
    top: 5px;
    left: 31px;
    z-index: 9999;
}

.search input {
    width: 100%;
    outline: none !important;
    border: none !important;
    height: 100%;
    padding: 6px 50px;
    background-color: #EAEAEA;
    border-radius: 10px;
    font-family: "SF-Pro-Rounded";
    font-size: 14px;
    color: #5D5D5D;
}

.search input::placeholder {
    color: #8E8E8E;
    font-family: "SF-Pro-Rounded";
    font-size: 14px;
}

.contact-head {
    margin-top: 25px;
    padding-left: 16px;
    padding-right: 16px;
    position: absolute;
    top: 85px;
    z-index: 9999;
}

#Your_message_list_you_have_6_contacts tspan {
    font-size: 12px !important;
    color: #5D5D5D;
    letter-spacing: 0px;
    font-family: "SF-PRO-Rounded_light" !important;
    text-transform: capitalize !important;

}

#Group_8318 {
    transform: translateY(-1px);
}

.chats-container {
    width: 100%;
    height: auto;
    transition: all 0.4s ease;
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    overflow-x: hidden;
    align-items: flex-start;
    justify-content: flex-start;
    margin-top: 143px;
}

.chat-item-container:not(:first-child) {
    margin-top: 5px;
}

.chat-item {
    width: 100%;
    transition: 0.3s;
    position: relative;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 10px 20px;
    background-color: #FAFAFA;
    border-radius: 10px;
}

.chat-avatar {
    display: flex;
}

.chat-avatar-chat img {
    width: 60px;
    height: 60px;
    border-radius: 12px;

}

.chat-info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    margin-left: 20px;
    height: 60px;
    padding-bottom: 3px;
    padding-top: 2px;
}

.chat-name {
    font-size: 14px;
    font-family: "SF-Pro-Rounded-med";
    letter-spacing: 0.01em;
    color: #5D5D5D;
    text-transform: capitalize;
}

.chat-typing {
    color: #007CFF;
    font-size: 12px;
    letter-spacing: 0.01em;
    text-transform: capitalize;
}

.chat-points {
    height: 7px;
    display: flex;
}

.chat-points svg:not(:first-child) {
    margin-left: 4.8px;
}

.chat-points svg:nth-child(1) g {
    transition: none;
    animation: points 1.2s ease-in 0.2s infinite;
}

.chat-points svg:nth-child(2) g {
    transition: none;
    animation: points 1.2s ease-in 0.4s infinite;
}

.chat-points svg:nth-child(3) g {
    transition: none;
    animation: points 1.2s ease-in 0.6s infinite;
}

.chat-points svg:nth-child(4) g {
    transition: none;
    animation: points 1.2s ease-in 0.8s infinite;
}

.chat-points svg:nth-child(5) g {
    transition: none;
    animation: points 1.2s ease-in 1s infinite;
}

.chat-points svg:nth-child(6) g {
    transition: none;
    animation: points 1.2s ease-in 1.2s infinite;
}

/* 
.chat-points svg:nth-child(4) g{
    transition-delay: 1.1s;

    animation: points 1.1s ease-in 2.8s infinite ;
}
.chat-points svg:nth-child(5) g{
    transition-delay: 1.3s;

    animation: points 1.3s ease-in 3.5s infinite ;
}
.chat-points svg:nth-child(6) g{
    transition-delay: 1.5s;

    animation: points 1.5s ease-in 4.2s infinite ;
} */
@keyframes points {
    0% {
        fill: transparent;
    }

    16% {
        fill: #007CFF;
    }

    100% {
        fill: transparent;
    }
}

.chat-last {
    width: 100%;
    white-space: pre-wrap;
    max-width: 100%;
    text-overflow: ellipsis;
    max-width: 203px;
    overflow: hidden;
    height: 32px;
    max-height: 31px;
    display: flex;
    align-items: flex-start;
    color: #8E8E8E;
    font-size: 12px;
    font-family: "SF-Pro-Rounded";
    letter-spacing: 0.01em;
}

.him .message-content {
    margin: 0 !important;
}

.message-content {
    width: 100%;
    white-space: pre-wrap;
    max-width: 100%;
    margin-left: 5px;
    text-overflow: ellipsis;
    max-width: 187;
    overflow: hidden;
    height: 32px;
    max-height: 31px;
    display: flex;
    align-items: flex-start;
    color: #8E8E8E;
    font-size: 12px;
    font-family: "SF-Pro-Rounded";
    letter-spacing: 0.01em;
}

.message-type {
    white-space: pre-wrap;
    max-width: 100%;
    margin-left: 5px;
    text-overflow: ellipsis;
    max-width: 187;
    overflow: hidden;

    display: flex;
    align-items: flex-start;
    color: #8E8E8E;
    font-size: 12px;
    font-family: "SF-Pro-Rounded";
    letter-spacing: 0.01em;
}

.chat-new {
    position: absolute;
    right: 18px;
    bottom: 10px;
    border-radius: 10px;
    background-color: #E2EEFB;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 30px;
}

.new-mes {
    color: #007CFF;
    font-size: 12px;
    font-family: "SF-Pro-Rounded-med";
    margin-left: 6px;
}

.chat-date {
    position: absolute;
    height: 16px;
    width: 47px;
    right: 18px;
    top: 13px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #8E8D92;
    font-size: 12px;
    font-family: "SF-Pro-Rounded";
}

.arrow-r {
    display: flex;
    height: 11px;
}

.date-clock {
    color: #8E8D92;
    font-size: 12px;
    font-family: "SF-Pro-Rounded";
    letter-spacing: 0.1em;
}

.active-chat {
    background-color: #F4F5F5 !important;
}

.chat-screen {
    display: flex;
    transition: all 0.4s ease;


    width: 100%;
    height: 100%;
    flex-direction: column;
    left: initial;

}

.nopad {
    padding: 0px !important;

}

.chat-screen-top {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 51px;
    background-color: #FAFAFA;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    padding-left: 20px;
    padding-right: 30px;
    flex-direction: row;
    box-shadow: rgba(0, 0, 0, 0.17) 0px 0.5px 0px;
}



.new-chat-num {
    font-size: 20px;
    color: #007CFF;
    font-family: "SF-Pro-text-semi";
    margin-left: 6px;
}

.user-top-chat {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-left: 11.2px;

}

.img-uer {
    width: 30px;
    position: relative;
    height: 30px;

    border-radius: 8px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px;
}

.img-uer::before {
    content: "";
    top: 0px;
    position: absolute;
    width: 30px;
    height: 30px;
    left: 0px;
    z-index: 999;
    box-shadow: inset rgba(255, 255, 255, 0.566) 0px 3px 6px;
}

.user-top-chat .img-uer img {
    width: 30px;
    height: 30px;
    border-radius: 8px;
    object-fit: cover;
}

.user-top-chat .user-name-top-chat {
    font-family: "SF-Pro-text-semi";
    margin-left: 11px;
    color: #5D5C5D;
    flex-direction: column-reverse;
    display: flex;
    font-size: 16px;
    text-transform: capitalize;
}

.user-status {

    font-size: 11px;
    letter-spacing: 0.026em;
    color: #007CFF;
    display: flex;
    font-family: "SF-PRO-Rounded_light";
    margin-top: 5px;
}

.chat-top-contact {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    position: absolute;
    top: 15px;
    right: 30px;
}

.vcall {
    margin-right: 20px;
}

img {
    object-fit: cover;
}

.chat-message-container::-webkit-scrollbar-thumb {
    background-color: #DDDDDD;
    border-radius: 5px;
}

.chat-message-container::-webkit-scrollbar {
    width: 8px;

}

.chat-message-container {
    width: 100%;
    overflow-x: hidden;
    overflow-y: scroll;
    height: calc(100% - 101px);
    background-color: #F7F7F7;
    margin-top: 51.5px;
    padding-bottom: 40px;
    padding-top: 40px;
    padding-left: 25px;
    padding-right: 20px;
}

.chat-input-container {
    width: 100%;
    left: 0px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    position: absolute;
    bottom: 0px;
    height: 50px;
    background-color: #FAFAFA;
    padding: 5px;
    z-index: 9;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
}

.input-chat {
    outline: none !important;
    background-color: #FAFAFA;
    border-radius: 15px;
    width: 295px;
    color: #404040;
    height: 40px;
    border: none;
    padding: 12px;
    font-family: "SF-Pro-text";
    font-size: 12px;

}

.input-chat-container {
    position: relative;
    display: flex;
    width: auto;
    height: auto;
}

.input-chat-container svg {
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 999999;
    cursor: text;
}

.wid31 {
    width: 301px;
}

.message-container {
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.message-container.self-align {
    justify-content: flex-end;
}

.message-body {
    display: flex;
    min-width: 80px;
    padding-top: 10px;
    padding-left: 20px;
    padding-right: 40px;
    padding-bottom: 23px;
    max-width: 357.5px;
    height: auto;
    min-height: 48px;
    white-space: pre-line;
    border-radius: 12px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 2px 10px;
    color: #5D5D5D;
    font-size: 14px;
    margin-top: 7px;
    position: relative;
    background-color: #B4FFD9;
    font-family: "SF-Pro-Rounded";
}

.self-align .message-body {
    background-color: #FFF9B4;
}

.message-date {
    display: flex;
    position: absolute;
    z-index: 999999999;
    left: 15px;
    bottom: 5px;
    width: auto;
}

.other-date {
    display: flex;
    position: absolute;
    z-index: 999999999;
    right: 15px;
    bottom: 5px;
    width: auto;
    font-family: "SF-Pro-text";
    font-size: 10px;
    color: #404040;
}

.recieve-date,
.read-date {
    display: flex;
    margin-left: 10px;
    align-items: center;
    z-index: 999999999;
    justify-content: space-between;
    width: 39px;
    font-family: "SF-Pro-text";
    font-size: 10px;
    color: #404040;
}

.sent-date {
    color: #404040;
    z-index: 999999999;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 39px;
    font-family: "SF-Pro-text";
    font-size: 10px;
}

.self-align .message-body:hover {
    min-width: 246px;
}

.message-date.hovers {
    display: none;
}

.message-body:hover .message-date {
    display: none;
}

.message-body:hover .message-date.hovers {
    display: flex !important;
}

.last-date {
    width: 100%;
    position: absolute;
    top: 61px;
    left: 0px;
    z-index: 9999999999;
    display: flex;
    align-items: center;
    justify-content: center;
}

.last-date-value {
    background-color: #404040;
    border-radius: 7px;
    width: auto;
    padding: 3px 9px;
    color: #FFFFFF;
    font-size: 12px;
    font-family: "SF-Pro-text";
}

.message-img {
    width: 315px;
    height: auto;
    object-fit: cover;
    border-radius: 12px;
}

.message-img-body {
    padding: 3px !important;
}

.message-img-body .other-date,
.message-img-body .message-date,
.message-img-body .message-date .sent-date,
.message-img-body .message-date .recieve-date,
.message-img-body .message-date .read-date {
    color: #FFFFFF;
}

.mic-chat {
    width: 288px !important;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.time-mic {
    font-size: 18px;
    font-family: "SF-Pro-text";
    color: #404040;
}

.bac40 {
    background-color: #F6F6F6 !important;
}

.wave-svg {
    margin-left: 86px;
}

.wave {
    display: flex;
    width: 40px;
}

.cancel-button {
    margin-left: 19px;
    font-size: 14px;
    font-family: "SF-Pro-text";
    color: #404040;
    cursor: pointer;

}

svg.redwave g g path {
    animation-name: lat;
    animation-duration: 1s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
}

@keyframes lat {
    0% {
        fill: #f42a2a8e;

    }

    50% {
        fill: #f42a2a;
    }

    100% {
        fill: #f42a2a8e;

    }
}

.audio-body {
    width: 340px;
    height: 67px;
    padding-top: 22.1px;
    padding-left: 20px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.player-line {
    width: 220px;
    height: 1px;
    background-color: #707070;
}

.player-cont {
    width: 220px;
    display: flex;
    height: 22px;
    margin-left: 20.5px;
    align-items: center;
    justify-content: flex-start;
    position: relative;
}

.player-contt {
    width: 149px;
    display: flex;
    height: 22px;
    margin-left: 20.5px;
    align-items: center;
    justify-content: flex-start;
    position: relative;
}

.wave-absolute {
    position: absolute;
    left: 0px;
    top: -19px;
}

.audio-message {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.player-time {
    font-size: 10px;
    padding: 3px 11.5px;
    width: fit-content;
    font-family: "SF-Pro-text";
    color: #404040;
    border: #9d9d9d79 0.4px solid;
    border-radius: 15px;
}

.player-timee {
    font-size: 10px;
    width: fit-content;
    font-family: "SF-Pro-text";
    color: #B3B3B3;
    border-radius: 15px;
}

.play-icon {
    margin-left: 15px;
}

.play-icon-me {
    margin-left: 7.5px;
}

.audio-body.him {
    padding-left: 24px !important;

}

.center-align {
    justify-content: center;
    margin-top: 25px;
}

.call-body {
    width: 343px;
    position: relative;
    box-shadow: rgb(0 0 0 / 16%) 0px 2px 10px;
    height: 50px;
    background-color: #FFDEDE;
    border-radius: 12px;
    display: flex;
    align-items: center;

    margin-left: 20px;
    justify-content: flex-start;
    padding-left: 41px;
}

.missed-body {
    font-family: "SF-Pro-text";
    font-size: 12px;
    color: #404040;
    display: flex;
    align-items: center;
    margin-left: 17px;
}

.mt25 {
    margin-top: 20px !important;
}

.first-chat,
.middle-chat,
.last-chat,
.lonely {
    position: relative;
}

.border-element {
    display: flex;
    overflow: hidden;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;

}

.first-chat .border-element {
    content: "";
    top: 50%;
    width: 1px;
    left: -1px;
    max-height: 50%;
    z-index: 999;

    position: absolute;
}

.last-chat .border-element {
    content: "";
    top: -6px;
    width: 1px;
    left: -1px;
    max-height: 70%;
    z-index: 999;

    position: absolute;
}

.middle-chat .border-element {
    content: "";
    top: -4px;
    width: 1px;
    left: -1px;
    max-height: calc(100% + 14px);
    z-index: 999;
    position: absolute;
}

.first-chat .border-element {
    width: 2px;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-start;
    overflow: hidden;
}

.border-element .border-child:not(:first-child) {
    display: flex;
    width: 0.8px;
    height: 2.8px;
    background-color: #404040;
    margin-top: 4.2px;
}

.border-element .border-child {
    display: flex;
    min-width: 0.8px;
    border-radius: 3px;
    min-height: 2.8px;
    background-color: #404040;
}

.self-align .middle-chat .border-element {
    left: initial !important;
    right: -1px;

}

.self-align .first-chat .border-element {
    left: initial !important;
    right: -2px;

}

.self-align .last-chat .border-element {
    left: initial !important;
    right: -1px;
}

.absolute-avatar {
    position: absolute;
    left: -17px;
    top: calc((100% - 35px) / 2);
    width: 34px;
    height: 36px;
    border-radius: 10px;
    padding-right: 4px;
    padding-top: 3px;
    z-index: 99999;

    background-color: #f7f7f7;
}

.self-align .message-body.text-body {

    padding-left: 20px !important;
    padding-right: 40px;
}

.message-body.text-body {
    padding-left: 40px !important;
    overflow-wrap: anywhere;
    white-space: pre-line;
}

.self-align .absolute-avatar {
    right: -17px;
    left: initial !important;
    padding-left: 4px;
}

.abs-avva {
    border-radius: 8px;
    filter: drop-shadow(rgba(0, 0, 0, 0.16) 0px 3px 6px);
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px;
    width: 30px;
    height: 30px;
    filter: drop-shadow(rgba(0, 0, 0, 0.16), 0px, 3px, 6px);
}

.message-img-body .bordse {
    content: "";
    position: absolute;
    background-color: #B4FFD9;
    width: 20px;
    border-top-right-radius: 12px;
    border-bottom-right-radius: 12px;
    left: 0px;
    height: 42px;
    z-index: 99999;
    top: calc((100% - 42px) / 2);
}

.self-align .message-img-body .bordse {
    content: "";
    position: absolute;
    background-color: #FEF8B3;
    width: 20px;
    border-top-left-radius: 12px;
    border-bottom-left-radius: 12px;
    border-top-right-radius: initial !important;
    border-bottom-right-radius: initial !important;
    right: 0px;
    left: initial !important;
    height: 42px;
    z-index: 99999;
    top: calc((100% - 42px) / 2);
}

.chat-item-container {
    display: flex;
    width: 100%;
    height: 80px;
    position: relative;
}

.chat-item-container .chat-item {
    z-index: 9999;
}

.chat-options-container {
    width: 100%;
    left: 0px;
    top: 0px;
    z-index: 999;
    position: absolute;
    display: flex;
    flex-direction: row;
    height: 80px;
}

.chat-option {
    display: flex;
    align-items: center;
    cursor: pointer;
    border-radius: 10px;
    padding: 15px 0px;
    justify-content: space-between;
    flex-direction: column;

    width: 85px;
    height: 80px;
    margin-left: 1px;
}

.chat-option div {
    font-size: 14px;
    font-family: "SF-Pro-text";
    color: #8E8D92;
}

.chat-1 {
    background-color: #FCF6EF;
}

.chat-2 {
    background-color: #EFF8FF;
}

.chat-3 {
    background-color: #F6F5FD;
    margin-left: 6px !important;
}

.chat-4 {
    background-color: #FFE8E8;
}

.chat-5 {
    background-color: #F0F0F0;
}

* {
    user-select: none;
}

.audio {
    opacity: 0px;
    z-index: -888;
    position: absolute;
}

.new-chats {
    position: fixed;
    top: 100px;
    right: 530px;
    display: flex;
    flex-direction: column;
    z-index: 9999;
    align-items: center;
    justify-content: flex-start;
}

.new-chat {
    display: flex;
    align-items: center;
    justify-content: flex-start;

}

.new-chat:not(:first-child) {
    margin-top: 15px;
}

.new-chat .img-cont img {
    width: 30px;
    height: 30px;
    border-radius: 8px;
    object-fit: cover;
}

.img-cont {
    display: flex;
    position: relative;
    border-radius: 8px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px;
}

.img-cont::before {
    content: "";
    box-shadow: inset rgba(255, 255, 255, 0.5) 0px 3px 6px;
    position: absolute;
    top: 0px;
    left: 0px;
    cursor: pointer;
    width: 30px;
    z-index: 88888888888888;
    height: 30px;
}

.lonely .border-element {
    display: none;
}

.chat-main {
    position: absolute;

}

.chat-screen {
    position: absolute;
    top: 0px;
    z-index: 1000 !important;
    transition: all 0.4s ease;

}

.active-wid {

    z-index: 99999;
}

.chat-page {
    width: 100%;
    height: 100%;
    display: flex;
    overflow-x: hidden;
    align-items: flex-start;
    justify-content: flex-start;
    position: relative;
}

.wide-view {
    animation-name: myAnimation;
    animation-duration: 0.3s;
    animation-timing-function: ease-in;
    animation-fill-mode: forwards;
    animation-iteration-count: 1;
}

@keyframes myAnimation {
    0% {
        width: 450px;
    }

    100% {
        min-width: 1000px;
        width: calc(100vw - 300px);
    }
}

.wide-view .whats-page {
    max-width: 100%;
}

.whats-page {
    width: 100%;
    height: calc(100%);
    max-height: 919px;
    border-radius: 10px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    position: relative;
}

#was {
    height: 40px;
    width: 36px;
}

.avatar-circle {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    font-size: 12px;
    padding: 10px;
    letter-spacing: 0.005em;
    align-items: center;
    justify-content: center;
    color: #F7F7F7;
    background-color: #5D5D5D;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px;
    font-family: "SF-PRO-Rounded-med" !important;
    text-transform: uppercase;
}