.filter_count_area {
    display: flex;
    height: 14px;
    margin-top: 19px;
    margin-bottom: 19px;
}

.filter_count_area_total {
    display: flex;
    font-size: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: 10px;
    margin-right: 11px;
    font-family: "SF-Pro-Rounded-regular";
    color: #5D5D5D;
}

.country_filter_group {
    display: flex;
    font-size: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    font-family: "SF-Pro-Rounded-light";
    color: #5D5D5D;
}

.country_filter_group_city {
    display: flex;
}

.country_filter_group_city .city_name {
    font-size: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    color: #5D5D5D;
    font-family: "SF-Pro-Rounded-regular";
}

.country_filter_group_city .city_count {
    font-size: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    color: #5D5D5D;
    font-family: "SF-Pro-Rounded-light";
}

.filter_count_area .filter_icon svg {
    height: 10px;
    width: 10px;
    margin-bottom: 2px;
    margin-top: 2px;
}

.shipment_main_statuses {
    display: flex;
    flex-direction: row;
    width: -webkit-fill-available;
    overflow-x: scroll;
}

.shipment_main_statuses .status_normal:not(:last-child) {
    margin-right: 29px;
}

.shipment_main_statuses .status_active:not(:last-child) {
    margin-right: 15px;
}

.shipment_main_statuses .status_not_active:not(:last-child) {
    margin-right: 5px;
}

.shipment_main_statuses .status_active:not(:first-child) {
    margin-left: 15px;
}

.shipment_main_statuses .status_not_active:not(:first-child) {
    margin-left: 5px;
}

.status_normal_square_tic_selected {
    height: 40px;
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    background-position: center;
    background-image: url("data:image/svg+xml,%3csvg width='99%25' height='99%25' style='padding-left: 1px;' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='calc(100%25 - 2px)' height='99%25' fill='none' rx='10' ry='10' stroke='%238E8E8EFF' stroke-width='1' stroke-dasharray='2%2c3' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
    border-radius: 10px;
    width: max-content;
    align-items: center;
    background-color: #FAFAFA;
    text-wrap: nowrap;
}

.status_normal_square_tic {
    height: 40px;
    box-shadow: 0px 2px 6px rgb(0, 0, 0, 0.1);
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    background-position: center;
    background-image: url("data:image/svg+xml,%3csvg width='99%25' height='99%25' style='padding-left: 1px;' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='calc(100%25 - 2px)' height='99%25' fill='none' rx='10' ry='10' stroke='%238E8E8EFF' stroke-width='1' stroke-dasharray='2%2c3' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
    border-radius: 10px;
    width: max-content;
    align-items: center;
    background-color: #FAFAFA;
    text-wrap: nowrap;
}


.status_icon_square_tic {
    margin-left: 10px;
    margin-right: 10px;
    height: 25px;
    width: max-content;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}

.status_icon_square_tic svg {
    margin-right: 10px;
}

.status_info_name_square_tic {
    font-size: 12px;
    font-family: "SF-Pro-Rounded-regular";
    display: flex;
    flex-direction: row;
    align-items: center;
    letter-spacing: calc((25 / 1000) * 1em);
    color: #5D5D5D;
    margin-right: 8px;
}

.status_normal_employee {
    width: -webkit-fill-available;
    min-height: 40px;
    height: 40px;
    box-shadow: 0px 2px 6px rgb(0, 0, 0, 0.1);
    display: flex;
    justify-content: center;
    align-items: center;
    background-position: center;
    border-radius: 20px;
    min-width: 372px;
    background-color: #F4F5F5;
    z-index: 10;
}

.status_normal_employee_type {
    margin-left: 5px;
    font-family: "SF-Pro-Rounded-regular";
    font-size: 12px;
}

.status_normal_employee svg {
    margin-top: 3px;
}

.shipment_main_statuses .status_normal {
    width: 256px;
    height: 50px;
    box-shadow: 0px 2px 6px rgb(0, 0, 0, 0.1);
    display: flex;
    justify-content: flex-start;
    cursor: pointer;
    background-position: center;
    background-image: url("data:image/svg+xml,%3csvg width='99%25' height='99%25' style='padding-left: 1px;' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='calc(100%25 - 2px)' height='99%25' fill='none' rx='10' ry='10' stroke='%238E8E8EFF' stroke-width='1' stroke-dasharray='2%2c3' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
    border-radius: 10px;
    min-width: 256px;
    margin-bottom: 8px;
    background-color: #F4F5F5;
}

.shipment_main_statuses .status_active {
    width: -webkit-fill-available;
    height: 50px;
    background-position: center;
    background-image: url("data:image/svg+xml,%3csvg width='99%25' height='99%25' style='padding-left: 1px;' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='calc(100%25 - 2px)' height='99%25' fill='none' rx='10' ry='10' stroke='%238E8E8EFF' stroke-width='1' stroke-dasharray='2%2c3' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
    border-radius: 10px;
    display: flex;
    justify-content: flex-start;
    cursor: pointer;
}

.shipment_main_statuses .status_not_active {
    width: fit-content;
    height: 50px;
    background-position: center;
    background-image: url("data:image/svg+xml,%3csvg width='99%25' height='99%25' style='padding-left: 1px;' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='calc(100%25 - 2px)' height='99%25' fill='none' rx='10' ry='10' stroke='%238E8E8EFF' stroke-width='1' stroke-dasharray='2%2c3' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
    border-radius: 10px;
    box-shadow: 0px 2px 6px rgb(0, 0, 0, 0.1);
    display: flex;
    justify-content: flex-start;
    cursor: pointer;
}

.status_normal_employee .status_icon {
    margin-left: 10px;
    margin-right: 10px;
}

.status_normal_employee .status_icon svg {
    margin-top: 12.5px;
    margin-bottom: 12.5px;
}

.shipment_main_statuses .status_normal .status_icon {
    margin-left: 10px;
    margin-right: 10px;
}

.shipment_main_statuses .status_normal .status_icon svg {
    margin-top: 12.5px;
    margin-bottom: 12.5px;
}

.status_normal_employee .status_info {
    margin-top: 8px;
    margin-bottom: 9px;
    display: flex;
    flex-direction: column;
}

.status_normal_employee .status_info .status_info_name {
    margin-bottom: 3px;
    font-size: 12px;
    font-family: "SF-Pro-Rounded-regular";
    display: flex;
    flex-direction: row;
    align-items: center;
    letter-spacing: calc((25 / 1000) * 1em);
    color: #5D5D5D;
    margin-left: 5px;
}

.status_normal_employee .status_info .status_info_info {
    display: flex;
    flex-direction: row;
}

.status_normal_employee .status_info .status_info_info .status_info_info_packages {
    display: flex;
    margin-right: 10px;
}

.status_normal_employee .status_info .status_info_info .status_info_info_packages .status_info_info_packages_num {
    font-size: 10px;
    color: #5D5D5D;
    font-family: "SF-Pro-Rounded-regular";
    margin-left: 5px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.status_normal_employee .status_info {
    margin-top: 8px;
    margin-bottom: 9px;
    display: flex;
    flex-direction: column;
}

.shipment_main_statuses .status_normal .status_info .status_info_name {
    margin-bottom: 3px;
    font-size: 12px;
    font-family: "SF-Pro-Rounded-regular";
    display: flex;
    flex-direction: row;
    align-items: center;
    letter-spacing: calc((25 / 1000) * 1em);
    color: #5D5D5D;
    margin-left: 5px;
}

.shipment_main_statuses .status_normal .status_info .status_info_info {
    display: flex;
    flex-direction: row;
}

.shipment_main_statuses .status_normal .status_info .status_info_info .status_info_info_packages {
    display: flex;
    margin-right: 10px;
}

.shipment_main_statuses .status_normal .status_info .status_info_info .status_info_info_packages .status_info_info_packages_num {
    font-size: 10px;
    color: #5D5D5D;
    font-family: "SF-Pro-Rounded-regular";
    margin-left: 5px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.status_info_info_products_num svg {
    margin-right: 5px;
}

.status_info_info_packages_num svg {
    margin-right: 5px;
}

.status_info_info_packages_num {
    font-size: 10px;
    color: #5D5D5D;
    font-family: "SF-Pro-Rounded-regular";
    margin-left: 5px;
    margin-right: 5px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.status_normal_employee .status_info .status_info_info .status_info_info_packages svg {
    margin-top: 2px;
    margin-bottom: 2px;
}

.status_normal_employee .status_info .status_info_info .status_info_info_products {
    display: flex;
    margin-right: 10px;
}

.status_normal_employee .status_info .status_info_info .status_info_info_products svg {
    margin-top: 2px;
    margin-bottom: 2px;
}

.status_normal_employee .status_info .status_info_info .status_info_info_products .status_info_info_products_num {
    font-size: 10px;
    color: #5D5D5D;
    font-family: "SF-Pro-Rounded-light";
    margin-left: 5px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.status_normal_employee .status_info .status_info_info .status_info_info_packages svg {
    margin-top: 2px;
    margin-bottom: 2px;
}

.shipment_main_statuses .status_normal .status_info .status_info_info .status_info_info_products {
    display: flex;
    margin-right: 10px;
}

.shipment_main_statuses .status_normal .status_info .status_info_info .status_info_info_products svg {
    margin-top: 2px;
    margin-bottom: 2px;
}

.shipment_main_statuses .status_normal .status_info .status_info_info .status_info_info_products .status_info_info_products_num {
    font-size: 10px;
    color: #5D5D5D;
    font-family: "SF-Pro-Rounded-light";
    margin-left: 5px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.status_info_info {
    display: flex;
    flex-direction: row;
    margin-right: 10px;
    align-items: center;
}

.status_inner_items_adjust {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.status_inner_items_adjust_col {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.status_info_info_products_num {
    font-size: 10px;
    color: #5D5D5D;
    font-family: "SF-Pro-Rounded-light";
    margin-left: 5px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.shipment_main_statuses .status_active .status_icon {
    margin-left: 10px;
    margin-right: 15px;
}

.shipment_main_statuses .status_active .status_icon svg {
    margin-top: 12.5px;
    margin-bottom: 12.5px;
}

.shipment_main_statuses .status_active .status_info {
    margin-top: 8px;
    margin-bottom: 9px;
    display: flex;
    flex-direction: column;
}

.shipment_main_statuses .status_active .status_info .status_info_name {
    margin-bottom: 3px;
    font-size: 12px;
    font-family: "SF-Pro-Rounded-regular";
    display: flex;
    flex-direction: row;
    align-items: center;
    letter-spacing: calc((25 / 1000) * 1em);
    margin-left: 5px;
}

.shipment_main_statuses .status_active .status_info .status_info_info {
    display: flex;
    flex-direction: row;
}

.shipment_main_statuses .status_active .status_info .status_info_info .status_info_info_packages {
    display: flex;
    margin-right: 10px;
}

.shipment_main_statuses .status_active .status_info .status_info_info .status_info_info_packages .status_info_info_packages_num {
    font-size: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    color: #5D5D5D;
    font-family: "SF-Pro-Rounded-regular";
    margin-left: 5px;
}

.shipment_main_statuses .status_active .status_info .status_info_info .status_info_info_packages svg {
    margin-top: 2px;
    margin-bottom: 2px;
}

.shipment_main_statuses .status_active .status_info .status_info_info .status_info_info_products {
    display: flex;
    margin-right: 10px;
}

.shipment_main_statuses .status_active .status_info .status_info_info .status_info_info_products svg {
    margin-top: 2px;
    margin-bottom: 2px;
}

.shipment_main_statuses .status_active .status_info .status_info_info .status_info_info_products .status_info_info_products_num {
    font-size: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    color: #5D5D5D;
    font-family: "SF-Pro-Rounded-light";
    margin-left: 5px;
}

.shipment_main_statuses .status_not_active .status_icon {
    margin-left: 10px;
    margin-right: 10px;
}

.shipment_main_statuses .status_not_active .status_icon svg {
    margin-top: 12.5px;
    margin-bottom: 12.5px;
}

.shipment_main_statuses .status_not_active .status_info {
    margin-top: 8px;
    margin-bottom: 9px;
    display: flex;
    flex-direction: column;
}

.shipment_main_statuses .status_not_active .status_info .status_info_name {
    margin-bottom: 3px;
    font-size: 12px;
    font-family: "SF-Pro-Rounded-regular";
    display: flex;
    flex-direction: row;
    align-items: center;
    letter-spacing: calc((25 / 1000) * 1em);
}

.shipment_main_statuses .status_not_active .status_info .status_info_info {
    display: flex;
    flex-direction: row;
}

.shipment_main_statuses .status_not_active .status_info .status_info_info .status_info_info_packages {
    display: flex;
    margin-right: 10px;
}

.shipment_main_statuses .status_not_active .status_info .status_info_info .status_info_info_packages .status_info_info_packages_num {
    font-size: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    color: #5D5D5D;
    font-family: "SF-Pro-Rounded-regular";
    margin-left: 5px;
}

.shipment_main_statuses .status_not_active .status_info .status_info_info .status_info_info_packages svg {
    margin-top: 2px;
    margin-bottom: 2px;
}

.shipment_main_statuses .status_not_active .status_info .status_info_info .status_info_info_products {
    display: flex;
    margin-right: 10px;
}

.shipment_main_statuses .status_not_active .status_info .status_info_info .status_info_info_products svg {
    margin-top: 2px;
    margin-bottom: 2px;
}

.shipment_main_statuses .status_not_active .status_info .status_info_info .status_info_info_products .status_info_info_products_num {
    font-size: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    color: #5D5D5D;
    font-family: "SF-Pro-Rounded-light";
    margin-left: 5px;
}

.status_normal_employee_parent {
    width: -webkit-fill-available;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='20' ry='20' stroke='%238E8E8EFF' stroke-width='1' stroke-dasharray='2%2c3' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
    margin-top: 10px;
    padding-bottom: 1px;
    border-radius: 20px;
    background-color: #F7F7F7;
}

.status_normal_employee_parent:not(:last-child) {
    margin-right: 5px;
}

@keyframes collapseItem100 {
    0% {
        left: 0px;
        margin-top: 0px;
        opacity: 1;
    }

    70% {
        margin-top: 0px;
        left: 500px;
        opacity: 0;
    }

    100% {
        left: 500px;
        margin-top: -100px;
    }
}

@keyframes collapseItem200 {
    0% {
        left: 0px;
        margin-top: 0px;
        opacity: 1;
    }

    70% {
        margin-top: 0px;
        left: 500px;
        opacity: 0;
    }

    100% {
        left: 500px;
        margin-top: -200px;
    }
}

@keyframes collapseItem300 {
    0% {
        left: 0px;
        margin-top: 0px;
        opacity: 1;
    }

    70% {
        margin-top: 0px;
        left: 500px;
        opacity: 0;
    }

    100% {
        left: 500px;
        margin-top: -300px;
    }
}

@keyframes collapseItem400 {
    0% {
        left: 0px;
        margin-top: 0px;
        opacity: 1;
    }

    70% {
        margin-top: 0px;
        left: 500px;
        opacity: 0;
    }

    100% {
        left: 500px;
        margin-top: -400px;
    }
}

@keyframes collapseItem500 {
    0% {
        left: 0px;
        margin-top: 0px;
        opacity: 1;
    }

    70% {
        margin-top: 0px;
        left: 500px;
        opacity: 0;
    }

    100% {
        left: 500px;
        margin-top: -500px;
    }
}

@keyframes collapseItemadd {
    0% {
        left: -500px;
        margin-top: -100px;
    }

    70% {
        margin-top: 0px;
        left: -500px;
        opacity: 0;
    }

    100% {
        left: 0px;
        margin-top: 0px;
        opacity: 1;
    }
}

.removedItem {
    position: relative;
    animation-duration: 1s;
    animation-timing-function: linear;
    animation-fill-mode: forwards;
}

.addedItem {
    position: relative;
    animation-name: collapseItemadd;
    animation-duration: 1s;
    animation-timing-function: linear;
    animation-fill-mode: forwards;
}

@keyframes greenItemadd {
    0% {
        box-shadow: 0px 3px 6px rgb(26, 255, 26);
    }

    80% {
        box-shadow: 0px 3px 6px rgb(26, 255, 26);
    }

    100% {
        box-shadow: 0px 3px 6px rgb(255, 255, 255);
    }
}

.addedItemGrean .employee_bulk_element_with_status .employee_bulk_element_usdt {
    animation-name: greenItemadd;
    animation-duration: 10s;
    animation-timing-function: linear;
    animation-fill-mode: forwards;
}

.is_there_a_list_of {
    position: absolute;
    bottom: 5px;
    right: 8px;
}

.rates_menu_absulute {
    position: absolute;
    bottom: 0px;
    max-height: 54px;
    left: 85px;
    display: flex;
    flex-direction: column-reverse;
    overflow-y: scroll;
    background-color: #5D5D5D;
    color: #F7F7F7;
    font-size: 12px;
    font-family: "SF-Pro-Rounded-light";
    border-radius: 5px;
    padding-left: 5px;
    padding-top: 3px;
    padding-bottom: 3px;
}

.rates_menu_absulute div {
    color: #F7F7F7;
    font-size: 12px;
    font-family: "SF-Pro-Rounded-light";
    cursor: pointer;
    padding: 2px;
    border-radius: 5px;
}

.rates_menu_absulute div:hover {
    color: #5D5D5D;
    background-color: #F7F7F7;
}

.instructions_title {
    font-family: "SF-Pro-Rounded-regular";
    font-size: 16px;
    color: #252525;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 15px;
}

.instructions_title svg {
    margin-right: 10px;
}

.instructions_list_dot {
    border-radius: 1px;
    border: 2px solid #5D5D5D;
    width: 0px;
}

.instructions_step {
    font-family: "SF-Pro-Rounded-light";
    font-size: 14px;
    color: #5d5d5d;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    padding-bottom: 15px;
    padding-left: 20px;
    padding-right: 15px;
}

.instructions_step svg {
    margin-right: 10px;
    min-width: 10px;
    margin-top: 4px;
}

@keyframes instructions {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.instructions_ticket {
    animation-name: instructions;
    animation-duration: 0.4s;
    animation-direction: normal;
    animation-timing-function: linear;
}