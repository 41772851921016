.hst.contries {
    left: -329% !important;
    width: 140px !important;
}

.lang-body.countries .parent-info .parent-icon {
    background-color: transparent !important;
}

.flag,
.language-flat.country-flag {
    width: 76px !important;
}

map,
.language-flat.country-map {
    width: 120px !important;

}

.lang-icon-country {
    max-height: 15px;
    position: relative;
}

.lang-icon-country:not(:first-child) {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 4px;

}

.lang-icon-country.active svg g rect {
    fill: #5D5D5D !important;
}

.lang-icon-country svg rect {
    fill: #8E8E8E !important;
}

.lang-icon-country svg path {
    fill: #F7F7F7 !important;
}

.lang-icon-country svg g text tspan {
    font-size: 9px !important;
}

.row-lay {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row !important;
    margin-top: 8px;
}

.point {
    position: absolute;
    left: 5px;
    top: -7px;
}

.official-det {
    opacity: 0;
    transition: 0.4s;
    width: auto;
    min-width: 84px;
    height: 22px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 10px;
    box-shadow: hsla(0, 0%, 0%, 0.2) 0px 3px 6px;
    background-color: #F7F7F7;
    border-radius: 5px;
    margin-left: 13.4px;
    color: #5D5D5D;
    text-transform: capitalize;
}

.lang-icon-country.active.Active-langs:hover~.official-det {
    opacity: 1;
}

.currency-sq {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #8E8E8E;
    border-radius: 2px;
    width: 15px;
    height: 15px;
}

.currency-sq svg path {
    fill: #F7F7F7;
}

.currency-sq.active {
    background-color: #5d5d5d;
}

.num-section {
    display: flex;
    flex-direction: row !important;
    align-items: center;
    justify-content: flex-start;
    margin-top: 15px;
}

.modal-input-head:not(:first-child) {
    margin-left: 17px;
}

input::-webkit-inner-spin-button,
input::-webkit-outer-spin-button {
    display: none;
}

.cityflag {
    width: 70px !important;
}

tspan {
    text-transform: uppercase;
}

.input-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-top: 10px;
    height: 18px;
}

.input-container input {
    outline: none;
    border: none;
    background: transparent;
    font-size: 12px;
    color: #404040;
    text-align: start;
    height: 20px;
    width: 100%;

    border-bottom: #CECDCD 0.5px solid;
}

.option-code:not(:first-child) {
    margin-left: 3px;
}

.options-code {
    display: flex;
    flex-direction: row;
    width: auto;
    max-width: 100%;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-start;
    height: 18px;
}

.option-code {
    width: auto;
    min-width: 40px;
    height: 18px;
    background-color: #DDDDDD;
    border-radius: 2px;
    color: #404040;
    font-size: 12px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}

.countries.lang-body .parent-info .parent-name {
    transform: translateX(0px) !important;
}

.red-cont {
    transition: 0.5s;
    color: rgb(255, 31, 72);
}

.sec-curr,
.sec-languages {
    transition: 0.5s;
}

.map,
.photo {
    width: 135px;
}

input::placeholder {
    color: #CECDCD;
    font-size: 10px;
}

.locat {
    position: absolute;
    top: 2px;
    right: 18px;
}

.icons-header {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.icon-header:not(:first-child) {
    margin-left: 20px;
}

.icon-header {
    display: flex;
    align-items: center;
    justify-content: center;
}

.icon-header.active svg path {
    fill: #404040;
}

.modal-half {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}

.map-container {
    width: 100%;
    overflow: hidden;
    max-width: 388px;
    background-color: #FFFFFF;
    height: 70px;
    border-radius: 10px;
    box-shadow: hsla(0, 0%, 0%, 0.16) 0px 1px 2px;
}

.location-details {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.location-text {
    color: #8E8E8E;
    font-size: 10px;
    letter-spacing: 0.025em;
    margin-left: 10px;
}

.half-s,
.half-r {
    width: 181px;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: center;
}

.half-row {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.leaflet-container {
    width: 100%;
    height: 100%;
}

.leaflet-bottom.leaflet-right,
.leaflet-top.leaflet-left {
    display: none !important;
}

.leaflet-div-icon {
    background: #fff0 !important;
    border: 1px solid #6660 !important;
}

.pho_code {
    color: #CECDCD;
    font-size: 12px;
    position: absolute;
    left: 2px;
    bottom: 3px;

}

.pho_code span {
    font-size: 12px;
}

.pho_code~input {
    padding-left: 28px !important;
}

.locat svg {
    position: static !important;
}

.f-c {
    display: flex;
    align-items: center;
    padding: 15px;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center !important;
    height: auto !important;
}

.reg-comp {
    width: 100%;
    display: flex;
    margin-top: 5px;
    padding-bottom: 4px;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
}

.reg-item {
    margin: 3px;
    width: auto;
    padding: 4px;
    background-color: #DDDDDD;
    color: #5D5D5D;
    display: flex;
    align-items: center;

    border-radius: 4px;
    height: auto;
}