.qr-container {
    display: flex;
}

.job-emp {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: center;
    margin-left: 25px;
    height: 100%;

}

input[type="date"]::-webkit-inner-spin-button,
input[type="date"]::-webkit-calendar-picker-indicator {
    display: none;
    -webkit-appearance: none;
}

.dept-emp {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.job-name {
    font-family: "SF-Pro-Rounded";
    font-size: 10px;
    color: #5d5d5d;
}

#p8 tspan {
    font-size: 8px;
}

.jop-code {
    color: #5D5D5D;
    font-size: 18px;
}

.input-label {
    color: #8E8E8E;
    font-family: "SF-Pro-Rounded";
    font-size: 10px;
}

.locat_static svg {
    position: static !important;
}

.date-emp {
    z-index: 899999999;
}

.view-body {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
}

.emp-item {
    width: 206px;
    height: 268px;
    cursor: pointer;
    border-radius: 20px;
    background-color: #F7F7F7;
    padding-top: 20px;
    margin: 5px;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: center;
}

.emp-name {
    color: #5D5D5D;
    font-size: 14px;
    font-family: "SF-PRO-Rounded";
    letter-spacing: 0.01em;
    text-transform: capitalize;
    margin-top: 18px;
    display: flex;

}

.emp-item .emp-photo {
    width: 100px;
    display: flex;

    height: 120px;
    border-radius: 20px;
    object-fit: cover;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 6px;

}

.emp-dept {
    font-size: 12px;
    font-family: "SF-PRO-Rounded_light";
    color: #8E8E8E;
    display: flex;
    margin-top: 3px;
}

.emp-dept-icon {
    display: flex;
    align-items: center;
    width: 130px;
    margin-top: 6px;

    justify-content: center;
}

.emp-info {
    width: 130px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-top: 6px;
}

.emp-job-type,
.emp-region {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.emp-job-type span {
    font-family: "SF-Pro-Rounded";
    font-size: 10px;
    color: #8E8E8E;
    display: flex;
    align-items: center;
    margin-left: 5px;
}

.emp-region span {
    font-family: "SF-Pro-Rounded";
    font-size: 10px;
    color: #8E8E8E;
    display: flex;
    align-items: center;
    margin-left: 5px;
}

.emp-job-type {
    margin-left: 11px;
}

.emp-job-type svg {
    height: 10px;
    width: 10px;
}

.filter-elem {
    width: 0px;
    display: flex;
    align-items: center;
    height: 30px;
    flex-direction: row;
    overflow: hidden;
    transition: all 0.6s ease;
    white-space: nowrap;
}

.active-filt {
    width: 100%;
}

.filter-opt {
    padding: 6px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: auto;
    max-height: 25px;
    font-family: "SF-PRO-Rounded_light";
    color: #5D5D5D;
    background-color: transparent;
    border-radius: 5px;
    font-size: 10px;
}

.filter-opt span {
    margin-left: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 10px;

    width: auto;
    max-height: 25px;
    font-family: "SF-PRO-Rounded_light";
    color: #5D5D5D;
}

.active-filte {
    background-color: #E8E8E8;
}

.avatar-img-emp {
    width: 43px;
    height: 50px;
    border-radius: 10px;
    position: relative;

    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px;
}

.avatar-img-emp::before {
    position: absolute;
    top: 0px;
    content: "";
    z-index: 9999;
    left: 0px;
    width: 100%;
    height: 100%;
    box-shadow: inset hsla(0, 0%, 100%, 0.521) 0px 3px 6px;
}

.avatar-img-emp img {
    width: 43px;
    height: 50px;
    border-radius: 10px;
}

.emp-name-mod {
    color: #404040;
    margin-left: 9px;
    text-transform: uppercase;
    font-size: 10px;
    font-family: "SF-PRO-Rounded";
}

.input-con {
    width: 100%;
    outline: none;
    border: none;
    background: transparent;
    font-size: 12px;
    color: #404040;
    display: flex;

    text-align: start;
    height: 20px;
    width: 100%;

    border-bottom: #CECDCD 0.5px solid;
}

.input-con input {
    width: 35px !important;
    margin: 0 !important;
    outline: none;
    border: none !important;
    background: transparent;
    font-size: 12px;
    color: #404040;
    text-align: start;
    height: 20px;
    width: 100%;
}

.mic-ico svg {
    position: static !important;
}

.inpt-text-helper {
    font-size: 12px;
    font-family: "SF-PRO-Rounded";
    color: #404040;
    left: 17px;
    position: absolute;
    text-transform: capitalize;
}

.active-emp {
    background-color: #F3FAF2;
}

.status_emp {
    display: none;
}

.status_emp {
    display: flex;
    width: 127px;
    justify-content: space-between;
    margin-top: 8px;
}

.deactive-emp {
    background-color: #FAF2F2;
    position: relative;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 6px;
}

.deactive-emp-status {
    display: none;
}

.deactive-emp .deactive-emp-status {
    display: flex;
    position: absolute;
    top: 10px;
    left: 12px;
}

.status_emp_item {
    display: flex;
}

svg g.active_gg text tspan {
    font-family: "SF-PRO-Rounded_light";
    font-size: 10px;
}

.cal.c {
    z-index: 9999;
}

.cal.c.cal::-webkit-calendar-picker-indicator {
    display: none;
}

.break-emp {
    background-color: #FAF6F0 !important;
    position: relative;
}

.break-emp .deactive-emp-status {
    display: flex !important;
    display: flex;
    position: absolute;
    top: 10px;
    left: 12px;
}

.loading {
    position: relative;
    background-color: #e9e9e996;
}

.cir {
    min-width: 35px;
    min-height: 35px;
    border-radius: 50%;
}

/* The moving element */
.loading::after {
    display: block;
    content: "";
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    transform: translateX(-100%);
    background: -webkit-gradient(linear, left top,
            right top, from(transparent),
            color-stop(rgba(255, 255, 255, 0.2)),
            to(transparent));

    background: linear-gradient(90deg, transparent,
            rgba(255, 255, 255, 0.2), transparent);

    /* Adding animation */
    animation: loading 0.8s infinite;
}

/* Loading Animation */
@keyframes loading {
    100% {
        transform: translateX(100%);
    }
}

.dm {

    display: flex;
    flex-direction: column;
    vertical-align: baseline;
    max-height: 100%;
    z-index: 9;
    max-width: 425px;
    overflow-anchor: none;
    position: relative;
    background: #E4DCD4;
    overflow-y: scroll;
    padding: 10px 5% 2pc;
    position: fixed;
    bottom: 30px;
    right: 490px;
    max-height: 30vh;
}

/*Switcher*/
.switchStatus {
    position: relative;
    display: inline-block;
    width: 55px;
    height: 20px;
    margin-top: 8px;
}

.switchStatus input {
    opacity: 0;
    width: 0;
    height: 0;
}

.switchStatus input:checked+.sliderStatus {
    background-color: #8e8e8e;
}

.switchStatus input:disabled+.sliderStatus {
    cursor: default;
}

.switchStatus input:checked+.sliderStatus:before {
    transform: translateX(20px);
    background-color: transparent;
    border-radius: 50%;
    border-top-color: transparent;
    border-left-color: transparent;
    border-right-color: transparent;
    box-shadow: inset -20px -20px 0 #d8e9ef;
}

.switchStatus .sliderStatus {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #F7F7F7;
    transition: 0.4s;
    border-radius: 20px;
    box-shadow: 0 0 0.25em rgba(67, 71, 85, 0.27), 0.2px 0.2em 24px 0 rgba(1, 29, 77, 0.15);
}

.switchStatus .sliderStatus:before {
    position: absolute;
    content: "";
    height: 12px;
    width: 12px;
    left: 6px;
    bottom: 4px;
    background-color: #8e8e8e;
    transition: 0.4s;
    border-radius: 50%;
    text-align: center;
    line-height: 12px;
}

.switchStatus input:checked+.sliderStatus .status_switch_no {
    display: none;
}

.switchStatus input:not(:checked)+.sliderStatus .status_switch_yes {
    display: none;
}

.status_switch_yes {
    font-size: 10px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    height: 20px;
    color: #F7F7F7;
    margin-left: 5px;
}

.status_switch_no {
    text-align: end;
    font-size: 10px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    width: 37px;
    height: 20px;
    color: #8e8e8e;
}