.TicketMessage {
    width: 300px;
    /* height: 359px; */
    display: flex;
    position: relative;
    padding-top: 15px;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding-left: 15px;
    padding-bottom: 21px;
    padding-right: 15px;
    border-radius: 10px;
    background-color: #FAFAFA;
    border: #dddddd7b 1px solid;
    /* overflow: scroll; */
}

.TicketHintMessage {
    width: 300px;
    /* height: 359px; */
    display: flex;
    position: relative;
    padding-top: 36px;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding-left: 15px;
    padding-bottom: 21px;
    padding-right: 15px;
    border-radius: 10px;
    background-color: #EFEFEF;
}

.isMessage.TicketHintMessage {
    background-color: rgb(228 232 252);
    min-width: 100% !important;
}

.transfer-body .TicketHintMessage:not(:first-child) {
    margin-left: 10px;
}

.TicketHintMessage.message-ticket {
    background-color: #E0F7CA;
    border: none;
}

.isMessage.TicketMessage {
    background-color: #E0F7CA;
    min-width: 275px;
    width: fit-content;
    border: none;
    padding-bottom: 0px;
}

.ticket-item .wallet {
    word-break: break-all;
}

.transfer-body .TicketMessage:not(:first-child) {
    margin-left: 10px;
}

.TicketMessage.message-ticket {
    background-color: #E0F7CA;
    border: none;
}

.TicketMessage .rs-icon {
    position: absolute;
    right: 0px;
    bottom: 0px;
    display: flex;
}

.isMessage .rs-icon {

    bottom: initial !important;
    top: 5px;
}

.rs-icon-message {
    position: absolute;
    right: 1px;
    top: 4px;
    display: flex;
}

.group_widg .--forwarded-hash-unapproved {
    width: 400px;
}

.group_widg .--forwarded-hash-unapproved .chat__msg {
    padding-left: 30px;
    padding-bottom: 10px;
    background-color: #FCFFFA;
    color: #000000;
}

.group_widg .--forwarded-hash {
    width: 400px;
}

.group_widg .--forwarded-hash .chat__msg {
    padding-left: 30px;
    padding-bottom: 10px;
}

.approve_and_send_area_approved {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-top: 10px;
}

.approve_and_send_area {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-top: 10px;
    max-height: 30px;
}

.approve_and_send_area_button_approved {
    /* color: #5d5d5d; */
}

.approve_and_send_area_time_approved {
    display: flex;
    align-items: center;
    z-index: 999999;
    min-width: -webkit-max-content;
    min-width: max-content;
    font-size: 12px;
    font-weight: 500;
    /* color: #5d5d5d; */
}

.approve_and_send_area_button {
    color: #1d79d9;
    text-decoration: underline;
    cursor: pointer;
    font-weight: 700;
    display: flex;
    flex-direction: row;
}

.approve_and_send_area_time {
    display: flex;
    align-items: center;
    z-index: 999999;
    min-width: -webkit-max-content;
    min-width: max-content;
    color: rgba(0, 0, 0, 0.45);
    font-size: 0.7rem;
    font-weight: 500;
}

.TicketHintMessage .rs-icon {
    position: absolute;
    right: 0px;
    bottom: 0px;
    display: flex;
    top: 43px;
}

.date-ticket {
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
    right: 15px;
    top: 19px;
    position: absolute;
}

.phone-ticket {
    font-size: 14px !important;
}

.id-ticket {
    font-size: 16px !important;
}

.amount-ticket.ticket-icon-name {
    font-family: "semi-bold" !important;
    white-space: nowrap;
    width: max-content;
}

.date-ticket span:first-child {
    margin-left: 2px;

}

.date-ticket span {
    margin-left: 2px;
    text-transform: lowercase !important;
    font-family: "SF-PRO-Rounded_light";
    color: #8E8E8E;
    display: flex;
    font-size: 0.7rem;
    font-weight: 500;
}

.withdrawl-element-container {
    margin-bottom: 15px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}

.withdrawl-icon {
    display: flex;
}

.arrow-withdrawl-icon {
    margin-left: 15px;
    display: flex;
}

.isMessage .arrow-withdrawl-icon path {
    fill: #007CFF;
}

.ref-number {
    display: flex;
    margin-left: 10px;
    font-family: "SF-Pro-Rounded-med";
    font-size: 14px;
    color: #8E8E8E;
    text-transform: uppercase;
    user-select: text;
}

.wallet {
    color: #5260C6 !important;
}

.date-name {
    font-size: 16px !important;
    font-family: "SF-PRO-Rounded_light" !important;
}

.yellow-name {
    color: #FFBF74 !important;
    font-family: "SF-PRO-Rounded_light" !important;
}

.ticket-icon-name {
    font-size: 16px;
    color: #5d5d5d;
    font-family: "SF-Pro-Rounded" !important;
    display: flex;
    user-select: text;
    margin-left: 10px;
}

.transfer-body {
    width: 100%;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
    height: 1800px;
    overflow: scroll;
}

.show-more-button {
    width: 300px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 0.5px solid #007CFF;
    border-radius: 10px;
    cursor: pointer;
    color: #007CFF;
}

.show-more-button:hover {
    color: #ffffff;
    border: 0.5px solid #ffffff;
    background-color: #007CFF;
}

.ticket-item {
    display: flex;
    align-items: center;
    margin-top: 5px;
}

.payment-type-red-bold {
    color: #f29595;
    font-weight: bold;
}

.ticket-icon-date {
    font-size: 12px;
    color: #5d5d5d;
    font-family: "SF-Pro-Rounded" !important;
    display: flex;
    user-select: text;
    margin-left: 10px;
    flex-direction: row;
    height: 16px;
    align-items: center;
}

.ticket-icon {
    display: flex;
    min-width: 11px;
    font-size: 10px;
    font-family: "Noto Emoji" !important;
}

.whatsapp-icon-user {
    display: flex;
    margin-left: 20px;
}

.forward-button {
    border-radius: 10px;
    position: absolute;
    top: 9px;
    right: 40px;
    z-index: 99999;
    background-color: #007CFF;
    color: #FAFAFA;
    font-size: 14px;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: "SF-Pro-Rounded-med" !important;
    cursor: pointer;
}

.blue-tick-for {
    color: #0DA9E5;
    width: 30px;
    height: 30px;
    display: flex;
    /* background-color: #fafafa; */
    border-radius: 50%;
    align-items: center;
    justify-content: center;
}

.hint_odoo_area {
    background-color: #E0F7CA;
    width: -webkit-fill-available;
    align-items: flex-start;
    display: flex;
    flex-direction: row;
    justify-content: center;
    position: absolute;
    top: 0px;
    right: 0px;
    left: 0px;
    border-radius: 10px;
}

.hint_odoo_area_inner {
    padding-top: 7px;
    padding-bottom: 7px;
    font-size: 12px;
    color: #5d5d5d;
}