.footer-site {
    position: fixed;
    bottom: 30px;
    left: 30px;
}

.username::placeholder {
    color: #8E8E8E;
    font-size: 18px !important;
}

.mobile-options {
    display: none;
}

.signup-main {
    padding-top: 0px !important;
    justify-content: center !important;
    max-height: 426px;
    position: relative;
}

.sign {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    max-height: 100%;
}

.bord g {
    transition: 0.3s;
}

.bord {
    display: none;
}

@media only screen and (max-width:428px) {
    .bord {
        display: block;
    }

    .active-button svg #Rectangle_4719 {
        fill: #404040;
    }

    .active-button svg g path {
        fill: #F7F7F7
    }

    .del-option,
    .del-option:hover .bord g {
        fill: #F5E5E5;
    }

    .del-option svg path {
        fill: #EA4343;
    }

    .signup-option .option-text {
        opacity: 1;
    }

    .change-option,
    .change-option:hover .bord g {
        fill: #E3E5F5;
    }

    .change-option svg path {
        fill: #4357EA;
    }

    .forget-user-option,
    .forget-user-option:hover .bord g {
        fill: #F0E4F5;
    }

    .forget-user-option svg path {
        fill: #B743EA;
    }

    .forget-option,
    .forget-option:hover .bord g {
        fill: #F5EBDE
    }

    .forget-option svg path {
        fill: #EB7F00;
    }

    .signup-main {
        padding-top: 0px !important;
        max-height: 567px;
        justify-content: center !important;
    }

    .mobile-options {
        display: block;
    }

    .signup-options {
        padding-bottom: 30px;
        position: static !important;
        margin-top: 5vh;

        flex-direction: column;
    }

    .option-text {
        opacity: 1 !important;
        margin-left: 20px;
        position: static !important;
        font-size: 12px;
        font-family: "SF-Pro-Rounded";
        color: #8E8E8E !important;
    }

    .signup-option:first-child {
        margin-top: 3px;
    }

    .signup-option .bord {
        position: absolute;
        top: 0px;
        left: 0px;
        z-index: 10;
    }

    #_15x15_photo_back,
    .option-text {
        z-index: 999;
    }

    .active-button~.signup-option {
        display: flex !important;
    }

    .signup-option {
        width: 100%;
        max-width: 350px;
        display: none !important;
        background-color: #F7F7F7;
        border-radius: 15px;
        height: 50px;
        display: flex;
        position: relative;
        margin-top: 2px;
        padding-left: 20px;
        align-items: center;
        margin-left: 0px !important;
        justify-content: flex-start !important;
        border: #DDDDDD 0.1% solid;
    }

    .footer-site {
        position: fixed;
        left: 0px;
        display: flex;
        width: 100vw;
        justify-content: center;
        align-items: center;

    }

    #wall-clock,
    #Path_19252 {
        display: none;
    }

    .username {
        max-width: 350px;
    }

    .relative-input svg {
        left: 42px;
    }

}

.avatar-component {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

}

.avatar-component .avatar img {
    width: 100px;
    height: 100px;
    border-radius: 20px;
    box-shadow: hsla(0, 0%, 0%, 0.2) 0px 3px 6px;
    object-fit: cover;
}

div.avatar {
    display: flex;
    width: auto !important;
    position: relative;
}

.app .avatar::before {

    top: 0px;
    left: 0px;
    width: 100px;
    height: 100px;
    position: absolute;
    box-shadow: inset hsla(0, 0%, 100%, 0.5) 0px 3px 6px;
}

.signup-container {
    margin-top: 19px;
}

.user-name {
    margin-top: 18px;
    font-size: 18px;
    font-family: "SF-PRO-Rounded_light";
    color: #404040;
    letter-spacing: 0.025em;
}

.signup-options {
    display: flex;
    justify-content: center;
    align-items: center;
    bottom: 0px;
    width: 100%;
    position: absolute;

}

.signup-option {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

.signup-option:not(:first-child) {
    margin-left: 30px;
}

.option-text {
    position: absolute;
    opacity: 0;
    font-size: 10px;
    letter-spacing: 0.025em;
    transition: 0.3s;
    overflow: unset;
    text-overflow: unset;
    bottom: -19px;
    white-space: nowrap;

}

.signup-option svg path {
    transition: 0.3s;
}

.del-option {
    color: #EA4343;
}

.del-option:hover svg path {
    fill: #EA4343;
}

.signup-option:hover .option-text {
    opacity: 1;
}

.change-option {
    color: #4357EA;
}

.change-option:hover svg path {
    fill: #4357EA;
}

.forget-user-option {
    color: #B743EA;
}

.forget-user-option:hover svg path {
    fill: #B743EA;
}

.forget-option {
    color: #EB7F00;
}

.forget-option:hover svg path {
    fill: #EB7F00;
}

.enter_user_name_button {
    width: 20px;
    height: 20px;
    top: 15px;
    right: 15px;
    position: absolute;
    z-index: 9999;
}

.enter_user_name_button svg {
    top: 0px;
    left: 0px;
    position: absolute;
    z-index: 9999;
}

.error_message_user_input {
    color: #e24747;
    padding-top: 10px;
    height: 20px;
}

.reset_password_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 90px;
}

.reset_password_container_2 {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
}

.reset_password_container_main_icon {
    width: 15px;
    height: 15px;
    margin-bottom: 8px;
}

.reset_password_container_main_welcome {
    font-size: 14px;
    font-family: "SF-PRO-Rounded_light";
    color: #A2A0A0;
    display: flex;
    flex-direction: row;
    width: max-content;
    line-height: 25px;
}

.reset_password_container_main_welcome_user {
    font-family: "SF-Pro-Rounded-regular";
    color: #7C7C7C;
    font-size: 14px;
    margin-right: 5px;
}

.reset_password_container_main_welcome_send {
    font-family: "SF-Pro-Rounded-regular";
    color: #3066CC;
    font-size: 14px;
    text-decoration: underline;
    cursor: pointer;
    margin-top: 12px;
}